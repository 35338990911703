<template>
  <div class="dialog-wrapper">
    <el-dialog title="重置密码" :visible.sync="dialogVisible" width="500px" :before-close="handleClose"
      :modal-append-to-body="false">
      <el-form :model="ruleFormPwd" ref="ruleFormPwd" :rules="rules" label-width="180px">
        <el-form-item label="用户名" prop="title">
          <span style="color: #fff">{{ username }}</span>
        </el-form-item>
        <el-form-item label="新密码" prop="password" >
          <el-input type="password" placeholder="请输入密码" v-model="ruleFormPwd.password" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input type="password" placeholder="请再次输入密码" v-model="ruleFormPwd.confirmPwd" />
        </el-form-item>
        <el-form-item class="btns mT20">
          <el-button class="btn back-btn" @click="handleClose">取消</el-button>
          <el-button class="btn submit-btn" @click="submitForm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShowChangePsdwordDialogSync: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: this.isShowChangePsdwordDialogSync,
      username: '',  //用户名
      userNameId:'' , // 登陆者的id
      ruleFormPwd: {   // 新/老密码
        password: '',
        confirmPwd: ''
      },
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 18, message: "6-18位英文/数字/字符组成", trigger: "blur" }
        ],
        confirmPwd: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { min: 6, max: 18, message: "6-18位英文/数字/字符组成", trigger: "blur" }
        ]
      }, 
    }
  },
  mounted() {
    this.username = sessionStorage.getItem("name")
    this.userNameId=sessionStorage.getItem('userNameId')
  },
  methods: {
    handleClose() {
      this.$refs.ruleFormPwd.resetFields();
      this.$emit('update:isShowChangePsdwordDialogSync', false)
    },
    submitForm() {
      this.$refs.ruleFormPwd.validate((valid) => {
        if (valid) {
          if (this.ruleFormPwd.password !== this.ruleFormPwd.confirmPwd) {
            this.$message.error("两次输入密码不一致");
          } else {
            this.httpAlertPwd();
          }
        }
      });
    },
    httpAlertPwd() {
      let params = {
        password: this.ruleFormPwd.password
      };
      this.$axios.put(`${this.baseURL}auth/authUser/password/${this.userNameId}`, this.$qs.stringify(params)).then((res) => {
        if (res.data.errCode == 200) {
          this.$message({
            message: "密码修改成功",
            type: "success",
            duration: 1000
          });
        setTimeout(()=>{
          this.$router.push('/login');
          sessionStorage.clear();
        },50)
          this.handleClose()
        }
      });
    }
  }
}
</script>

<style lang="scss"  scoped>
.dialog-wrapper {
  text-align: left
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

::v-deep .el-input {
  width: 200px;
  height: 32px;
}
::v-deep .el-form-item__label{
  line-height: 52px;
    height: 52px;
}
::v-deep .el-form-item__content{
  line-height: 52px;
    height: 52px;
}

::v-deep .el-form-item__error{
  color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 0px;
    position: absolute;
    top: 45px;
    left: 0;
}
</style>