<template>
  <div class="gisWin">
      <div class="main">
          <div class="line1 box">
            <div class="color1 fs12">YB - BK - ZK - 1： </div>
            <div class="color1 fs12">南幅边动应变</div>
          </div>
          <div class="line2">
              <img src="" alt="">
          </div>
          <div class="box around line3">
              <div class="">
                   <div class="fs14 color2">应变</div>
                    <div class="fs12 color3">监测指标类型</div>
              </div>
              <div>
                  <div class="fs14 color2">2021.4.12</div>
                  <div class="fs12 color3">设备安装时间</div>
              </div>
              <div>
                  <div class="fs14 color2">在线</div>
                  <div class="fs12 color3">设备状态</div>
              </div>
          </div>
          <div class="box around">
              <img src="../../assets/images/img/sanjiao_zhishi_icon.png" alt="">
          </div>
      </div>

  </div>
</template>

<script>

export default {
  name: 'gisWin',
  data(){
      return{

      }
  },
  //创建
  created(){

  },

  //安装
  mounted(){

  },
  
  //方法
  methods:{

  }


}
</script>
<style  scoped lang="scss">
    .gisWin{
        width: 456px;
        height: 296px;
        background: #fff;
        // border: 1px solid #ccc;
    }
    .line1{
        padding-left: 17px;
        padding-top: 17px;
        font-weight: 400;
    }
    .line2{
        height: 198px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .color1{
        color: #000616;
    }
    .color2{
        color: #282D32;
    }
    .color3{
        color: rgba($color: #000616, $alpha: 0.5);
    }

</style>