<template>
  <div class="videoWin winStyle" v-if="closeVideo == 1" >
     <div class="close pointer" @click="closeWin"></div>
     <div class="main box box_column">
         <div class="top">
             <div>视频查看</div>
         </div>
         <div class="flex1 bottom box center">
             <div class="left">
                 <!-- <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
                <el-tree
                            ref="tree"
                            :data="data"
                            :default-expand-all="true"
                            :props="defaultProps"
                            node-key="id"
                            :current-node-key="currentNodeKey"
                            
                            :highlight-current="true"
                            @node-click="handleNodeClick"
                            >
                            <span class="custom-tree-node textL" slot-scope="{node, data }">
                                    <!-- <span>
                                        {{ data.name }}
                                    </span>
                                    <span v-if="data.list.length !='0'">({{data.list.length}})</span> -->
                                    <!-- <span v-if="data.parentId=='0'" class="color1">
                                        {{ data.videoName }}
                                    </span> -->
                                    <span class="color2">
                                        {{ data.videoName }}
                                    </span>
                                    <span v-if="data.parentId=='0'">({{data.list.length}})</span>
                            </span>
                    </el-tree>
             </div>
             <div class="right flex1">
                <video id="myVideoWin" class="video-js vjs-default-skin" muted width="100%" heigt="100%">
                    <source :src="src" type="application/x-mpegURL" />
                </video>
             </div>
         </div>
     </div>
  </div>
</template>

<script>

import videojs from 'video.js';
export default {
  name: 'videoWin',
  props:['closeVideo','src'],
  watch:{
    closeVideo(n,o){
        if(n==1){
           console.log('播放',this.src);
        //    this.close();
           this.startPlay();
        }
    }
  },
  data(){
      return{
        //   src:'',
           data: [],
            defaultProps: {
                children: 'list',
                label: 'videoName'
            }
      }
  },
  //创建
  created(){
    console.log('closeVideo', this.closeVideo);
  },

  //安装
  mounted(){
    // this.src = "http://cctvalih5ca.v.myalicdn.com/live/cctv5_2/index.m3u8";
    // var player  = videojs('myVideo');
    // videojs('myVideo').src(this.src)
    // player.play();
    //  关闭
    this.videoData();   //视频列表请求

  },
  
  //方法
  methods:{
    //  关闭
      close(){
        console.log('关闭');
        var player = videojs('myVideoWin');
        player.dispose();
      },

    //  播放
      startPlay(){
          console.log('src', this.src);
          
        // this.src = "http://cctvalih5ca.v.myalicdn.com/live/cctv5_2/index.m3u8";
        this.$nextTick(()=>{
            var player  = videojs('myVideoWin');
            videojs('myVideoWin').src(this.src)
            player.play();
        })
      },
      closeWin(){
          console.log('关闭视频窗口');
          this.close();
          this.closeVideo = 0;
          this.$emit('getAdd1', '0');          // 给父组件的
      },

        // 视频列表请求
       videoData(){
            this.$axios.post(`${this.baseURL}pandect/select/video?videoName=${name}`).then(res => {
                console.log('获取视频数据', res);
                var data = res.data.data;
                data.forEach((item, index) => {
                    item.videoName = item.name;
                    item.parentId = 0;
                });
                this.data = data;
            }).catch(err=>{ 
            })
        },

        handleNodeClick(e){

            // var player  = videojs('myVideoWin');
            // player.dispose();
            this.src = e.videoUrl;

            setTimeout(()=>{
                var player  = videojs('myVideoWin');
                videojs('myVideoWin').src(this.src)
                player.play();
            },300)
        },

        handleClose(e) {
        console.log('点击空白处');
        this.win = 0;  //隐藏桥梁弹框
        if(this.win1 == 1){
            var player  = videojs('myVideo');
            player.dispose();
            this.videoList.forEach((item, index)=>{
                let player  = videojs('myVideoList'+ index);
                player.dispose();    //dispose销毁
            })
            this.win1 = 0;
        }
    },


  }


}
</script>
<style  scoped lang="scss">
    .videoWin{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .main{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .bottom{
        height: 100%;
        padding-top: 20px;
    }
    .left{
        width: 240px;
        height: 478px;
        background: rgba($color: #042066, $alpha: 0.4);
        border-radius: 12px;
        margin-right: 8px;
        overflow-y: auto;
    }
    .right{
        width: 100%;
        height: 100%;
        border-radius: 8px;

    }
    #myVideoWin{
        width: 100%;
        height: 100%;
    }
    .close{
        position: absolute;
        width: 24px;
        height: 24px;
        top: -30px;
        right: -30px;
        background: url('../../assets/images/all/guanbi_icon.png');
        background-size: 100% 100%;
    }


     // 树的样式
    ::v-deep .el-tree{
        background: transparent;
        .el-tree-node .el-tree-node__content{
            color: #CCDAFF;
        }
        .el-tree-node__content:hover{
            color: #fff;
            background-color: transparent;
        }

        .el-tree-node.is-current > .el-tree-node__content{
            color: #fff;
            background-color: transparent !important;    //选中的背景色
        }
        .color1{
            font-size: 16px;
        }
        .color2{
            font-size: 14px;
        }
    }

</style>