<template>
  <div class="allView winStyle">
    <!-- gis 展示 -->
    <!-- <div class="gisWin" id="gisWin">
          <gisWin></gisWin>
      </div> -->
    <!-- <div  style="z-index:99;color:red;margin-left:800px" class="" @click="gisData1">9999999</div> -->

    <!-- 点击gis 弹框 -->
    <div class="gisBox">
      <!-- <giwBox></gisBox> -->
    </div>
    <!-- 单桥信息 -->
    <div v-if="all == 0" style="z-index: 999">
      <aloneLook ref="alonelookzhw" @getAloneWarn="getAloneWarn"></aloneLook>
    </div>
    <!-- 总览桥梁弹窗 -->

    <div id="aloneWin" :class="aloneWin == 1 ? 'ztop' : 'zBottom'">
      <aloneWin ref="aloneWin" v-if="typeAllFlag && numberAll.count"></aloneWin>
    </div>

    <!-- 测点 触摸弹框-->
    <div id="targetBox" :class="targetBox == 1 ? 'ztop' : 'zBottom'">
      <targetWin v-if="typeAllFlag && numberAll.count"></targetWin>
    </div>

    <!-- 数据图表展示 -->
    <div id="largeWin" class="dataWin" :class="closeShow == 1 ? 'a' : 'b'">
      <dataShow :closeShow="closeShow" @getAdd="getAdd"></dataShow>
    </div>
    <!-- 视频窗口 -->
    <div class="videoWin" :class="closeVideo == 1 ? 'a' : 'b'">
      <videoWin :src="src" :closeVideo="closeVideo" @getAdd1="getAdd1"></videoWin>
    </div>
    <!-- 轻度预警， -->
    <!-- <navTitle></navTitle> -->
    <div class="main">
      <!-- 左侧 1 -->
      <div v-if="all == 1" id="leftTop1" class="leftTop1">
        <div class="left1 mB10">
          <div>桥梁群总览</div>
          <div class="box flexBottom">
            <div class="fs32">{{ numberAll.count }}</div>
            <div>座</div>
          </div>
          <div class="box flexBottom">
            <div class="fs32">{{ numberAll.length }}</div>
            <div>米</div>
          </div>
        </div>
        <div class="left2">
          <ul>
            <li v-for="(item, index) in numberList" :key="index">
              <div>
                <div>{{ item.name }}</div>
              </div>
              <div class="box center">
                <div style="margin-right: 13px">
                  <img :src="item.img" alt="" />
                </div>
                <div class="fs36">{{ item.number }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 左侧 2  -->
      <div v-if="all == 1" class="leftTop2">
        <ul @click="checkWarningBrige" class="pointer">
          <li v-for="(item, index) in warnList" :key="index" class="box around">
            <div class="box center">
              <div style="width: 16px; height: 22px" class="mR5">
                <img :src="item.src" alt="" style="width: 100%; height: 100%" />
              </div>
              <div class="box flexBottom">
                <div class="fs16" style="margin-right: 6px">{{ item.name }}</div>
                <div class="fs22">{{ item.number }}</div>
                <div class="fs12">座</div>
              </div>
            </div>
          </li>
        </ul>

        <!-- 桥梁预警状态列表 弹框 -->
        <div class="newTree warningzhw" v-if="warningSelect == 1">
          <div class="winTitle box justify" :class="bottom == 1 ? 'winTitle' : 'winTitle1'">
            <div>桥梁预警列表</div>
            <!-- <div class="pointer" @click="winBottom">下拉</div> -->
          </div>
          <div class="winBottom" v-if="bottom == 1">
            <!-- <div class="fu">
              <el-input v-model="name" placeholder="请输入桥梁名称"></el-input>
              <div class="zi pointer" @click="bridgeTreeData">
                <img src="../assets/images/all/sousuo.png" alt="" />
              </div>
            </div> -->
            <el-tree ref="tree" :data="warningData" :default-expand-all="true" :props="defaultProps" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true">
              <span class="custom-tree-node textL" slot-scope="{ node, data }">
                <!-- <span>
                                        {{ data.name }}
                                    </span>
                                    <span v-if="data.list.length !='0'">({{data.list.length}})</span> -->
                <!-- <span v-if="data.targetId">11</span> -->
                <!-- <i style="margin-right: 10px"></i> -->
                <!-- <span v-if="data.parentId=='0'">
                                        {{ data.name }}  
                                    </span> -->
                <span>
                  {{ data.name }}
                </span>
                <span v-if="data.parentId == '0'">({{ data.list.length }})</span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>

      <div v-if="all == 0" class="leftTop22">
        <!--单桥预警状态 -->
        <ul :class="projectId == 10009 ? 'site9' : ''" @click="showAloneLook">
          <li v-for="(item, index) in warnList" :key="index" class="box around">
            <div class="box center">
              <div style="width: 16px; height: 22px" class="mR5"><img :src="item.src" alt="" style="width: 100%; height: 100%" /></div>
              <div class="box flexBottom">
                <div class="fs16" style="margin-right: 6px">{{ item.name }}</div>
                <div class="fs22">{{ item.number }}</div>
                <div class="fs12" v-if="all == 1">座</div>
                <div class="fs12" v-if="all == 0">个</div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- 桥梁搜索 -->
      <!-- <div class="search pointer" @click.stop="searchShow" v-if="all == 1">
        <el-tooltip content="桥梁列表" placement="left">
          <img src="../assets/images/all/sousuo1.png" alt="" />
        </el-tooltip>
      </div> -->
      <!-- 右侧导航 -->
      <div class="operation">
        <!-- 重定向， 放大，缩小 -->
        <div class="maxmin box around">
          <div>
            <div class="mB10 yuan1 pointer box around">
              <!-- <div class="iconfont icon-fangda_icon" @click="gisData('resetFly')"></div> -->
              <el-tooltip content="复位" placement="left">
                <img src="../assets/images/all/locate.png" alt="" @click="gisData('resetFly')" />
              </el-tooltip>
            </div>
            <div class="mB10 yuan pointer" @click="gisData('cameraforward')">
              <el-tooltip content="放大" placement="left">
                <img src="../assets/images/all/fangda.png" alt="" />
              </el-tooltip>
            </div>
            <div class="yuan pointer" @click="gisData('cameraback')">
              <el-tooltip content="缩小" placement="left">
                <img src="../assets/images/all/suoxiao.png" alt="" />
              </el-tooltip>
            </div>
          </div>
        </div>
        <!-- 监视器视频按钮 -->
        <div style="margin-top: 10px" class="pointer" @click.stop="tvShow">
          <el-tooltip content="视频监控" placement="left">
            <img src="../assets/images/all/jianshiqi.png" alt="" />
          </el-tooltip>
        </div>
        <!-- 临时 -->
        <!-- <div class="yuan pointer mT10" @click="closeWin">
                        <img src="../assets/images/all/jianshiqi.png" alt="">
                  </div>
                  <div class="yuan pointer mT10" @click="closeWin1">
                        <img src="../assets/images/all/jianshiqi.png" alt="">
                  </div>
                  <div class="yuan pointer mT10" @click="changeAll">
                        <img src="../assets/images/all/jianshiqi.png" alt="">
                  </div> -->
      </div>
      <!-- 桥梁 弹框 -->
      <!-- <div class="win newTree" v-if="win == 1 && all == 1" v-clickoutside="handleClose"> -->
      <div class="win newTree" v-if="false" v-clickoutside="handleClose">
        <div class="winTitle box justify" :class="bottom == 1 ? 'winTitle' : 'winTitle1'">
          <div>桥梁监测物列表</div>
          <!-- <div class="pointer" @click="winBottom">下拉</div> -->
        </div>
        <div class="winBottom" v-if="bottom == 1">
          <div class="fu">
            <el-input v-model="name" placeholder="请输入桥梁名称"></el-input>
            <div class="zi pointer" @click="bridgeTreeData">
              <img src="../assets/images/all/sousuo.png" alt="" />
            </div>
          </div>
          <el-tree ref="tree" :data="data" :default-expand-all="true" :props="defaultProps" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true" @node-click="handleNodeClick">
            <span class="custom-tree-node textL" slot-scope="{ node, data }">
              <!-- <span>
                                        {{ data.name }}
                                    </span>
                                    <span v-if="data.list.length !='0'">({{data.list.length}})</span> -->
              <!-- <span v-if="data.targetId">11</span> -->
              <!-- <i style="margin-right: 10px"></i> -->
              <!-- <span v-if="data.parentId=='0'">
                                        {{ data.name }}  
                                    </span> -->
              <span>
                {{ data.name }}
              </span>
              <span v-if="data.parentId == '0'">({{ data.list.length }})</span>
            </span>
          </el-tree>
        </div>
      </div>

      <!-- 视频监控 -->
      <div class="win1" v-if="win1 == 1" v-clickoutside="handleClose">
        <div class="winTitle box justify" :class="bottom == 1 ? 'winTitle' : 'winTitle1'">
          <div>视频监控</div>
          <!-- <div class="pointer" @click="winBottom">下拉</div> -->
        </div>
        <!-- <div class="mT10 mL20 mR20 mB10">
                    <el-select v-model="value" value-key="id" placeholder="请选择" @change="changeTarget">
                        <el-option-group
                            v-for="group in options"
                            :key="group.id"
                            :label="group.name">
                            <el-option
                                v-for="item in group.list"
                                :key="item.id"
                                :label="item.videoName"
                                :value="item">
                            </el-option>
                            </el-option-group>    
                    </el-select>
                </div> -->

        <!-- 萤石云 -->
        <div id="myVideo" v-if="projectName == 'shuohuang' || projectName == 'huihe'"></div>

        <!-- <div id='myVideoList1'></div> -->

        <div class="noVideo box around" v-if="projectName != 'shuohuang' || projectName == 'huihe'">
          <div class="">
            <img src="../assets/images/alone/ic_jiankong_zanwu.png" alt="" />
            <div>暂无视频</div>
          </div>
        </div>

        <!-- <div  class="videoBox pointer" v-if="videoList.length>0">
                    <video id="myVideo" class="video-js vjs-default-skin" muted width="100%" heigt="270px" @click="videoWin()">
                        <source :src="src" type="application/x-mpegURL" />
                    </video>
                </div> -->

        <!-- <div class=" videoBox1Fu box wrapFlex" v-if="videoList.length>0">
                    <div  v-for="(item,index) in videoList" :key="index"  class="videoBox1">
                        <video :id="'myVideoList' + index" style="" class="video-js vjs-default-skin video"  muted @click="changeVideo(item)">
                            <source :src="item.videoUrl" type="application/x-mpegURL" />
                        </video>
                    </div>
                </div> -->
      </div>

      <!-- 总览桥型类型 -->
      <div class="qiaoType" v-if="qiaoType == 1">
        <div class="top" style="height: 40px; line-height: 40px">桥梁类型</div>
        <div class="bottom" style="width: 248px; height: 63px">
          <ul class="ul1 box">
            <li v-for="(item, index) in queryType" :key="index" @click="changeType(item)" class="pointer">
              <div class="box box_column" :class="item.tid == 3 || 4 ? 'kuandu' : ''">
                <div class="box around center li1" :class="typeAll.includes(item.tid) == true ? 'acLi' : ''">
                  <div class="iconfont box around gong" :class="item.icon" alt=""></div>
                </div>
                <div class="ul1Title fs12">{{ item.tname }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 单桥类型  aloneType -->
      <div class="aloneType" v-if="aloneType == 1">
        <div v-for="(item, index) in aloneData" :key="index">
          <div class="top_1" v-if="item.list && item.list.length">{{ item.name }}</div>
          <div class="bottom_1 box flexW" v-if="item.list && item.list.length">
            <div v-for="(item1, index1) in item.list" :key="index1" class="itemBox box box_column center pointer" @click="getType(item1)">
              <!-- <div class="itemBox1">{{index1}}</div> -->
              <div class="itemBox1 box center around" :class="aloneTypeArr.includes(item1.id) == true ? 'itemBox2' : ''">
                <span style="color: #c3d2f6" class="iconfont" :class="item1.icon"></span>
              </div>
              <div class="fs10">{{ item1.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 桥型类型 -->
      <!-- <div class="qiaoType" v-if="qiaoType == 1">
                <div class="top" style="width:240px; height:40px">桥梁类型</div>
                <div class="bottom" style="width:240px; height:63px"></div>
            </div> -->

      <!-- 切换2d和3d地图 -->
      <div class="changeDitu pointer" @click="gisData('mapchange')" v-if="all == 1">
        <el-tooltip content="切换地图" placement="left">
          <img src="../assets/images/all/dituqiehuan_grey_icon.png" alt="" />
        </el-tooltip>
      </div>

      <!-- 搜索类型 -->
      <div class="listSearch pointer" @click="typeShow">
        <el-tooltip :content="all == 1 ? '桥梁筛选' : '测点筛选'" placement="left">
          <img v-if="all == 1" src="../assets/images/all/qiaoliangleixing.png" alt="" />
          <img v-else src="../assets/images/all/dituqiehuan_icon.png" alt="" />
        </el-tooltip>
      </div>

      <!-- 单桥搜索类型 -->
      <!-- <div class="listSearch pointer"  @click="typeShow">
                <img src="../assets/images/all/qiaoliangleixing.png" alt="">
            </div> -->
    </div>
    <!-- <iframe id="ditu" src="https://www.thingjs.com/pp/b11aea19e425d75509af50ad" width="100%" height="100%"></iframe> -->
  </div>
</template>

<script>
import videojs from "video.js";
import dataShow from "../components/alone/dataShow.vue"; //数据查看
import navTitle from "../components/allView/navTitle.vue"; //文字导航
import videoWin from "../components/allView/videoWin.vue"; //视频窗口
import aloneLook from "../components/alone/aloneLook"; //单桥样式
import gisWin from "../components/allView/gisWin";
import aloneWin from "../components/alone/aloneWin"; //单桥弹框
import targetWin from "../components/alone/targetWin"; //单桥的测点弹框
import Alone from "./alone.vue";
import qs from "qs";

const warnListObj = (name, list) => {
  console.log("name", name, "list", list);
  let list1 = [];
  for (let j = 0; j < list.list.length; j++) {
    let obj1 = {
      name: list.list[j]
    };
    list1.push(obj1);
  }

  return {
    name: `${name}(${list.count})`,
    count: list.count,
    list: list1
  };
};

const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  }
};

import EZUIKit from "ezuikit-js";
export default {
  name: "allView",
  directives: { clickoutside },
  components: {
    dataShow,
    navTitle,
    videoWin, //视频窗口
    aloneLook, //单桥信息
    gisWin, //gis 弹框
    aloneWin, //单桥触摸弹窗
    targetWin, //测点戳莫弹框
    Alone //单桥点击弹框
  },

  watch: {
    // 如果all =0； 就是进入单桥了。 发送给导航
    all(n, o) {
      console.log("n,o", n, o);
      if (n == 0) {
        //0进入单桥
        console.log("进入单桥 监听");

        this.qiaoType = 0; //隐藏总览类型
        //  this.aloneType = 1    //显示单桥类型
        //  this.aloneTypeData();   //获取单桥类型     //----
        // this.aloneWarn(); //单桥告警统计
        this.aloneTypeData(); //单桥类型

        this.$bus.$emit("all", "0");
        sessionStorage.setItem("all", 0);
        this.win = 0;
      } else {
        if (this.$refs["alonelookzhw"]) {
          this.$refs["alonelookzhw"].getClearRate();
        }

        this.$bus.$emit("all", "1");
        sessionStorage.setItem("all", 1);
      }
    }
  },

  data() {
    return {
      typeAllFlag: false,
      warningSelect: 0,
      numberAll: {}, //总座数，总长度
      numberList: [
        { id: "1", name: "悬索桥（座）", number: "", img: require("../assets/images/all/qiao_type1.png") },
        { id: "2", name: "斜拉桥（座）", number: "", img: require("../assets/images/all/qiao_type2.png") },
        { id: "3", name: "梁桥（座）", number: "", img: require("../assets/images/all/qiao_type3.png") },
        { id: "4", name: "梁桥（座）", number: "", img: require("../assets/images/all/qiao_type4.png") }
      ],
      queryType: [], //桥梁类型
      typeAll: [], //桥梁选中
      warningData: [],
      warnList: [
        { id: "1", name: "轻度预警", number: "", src: require("../assets/images/all/qingduyujing_icon1.png") },
        { id: "1", name: "中度预警", number: "", src: require("../assets/images/all/qingduyujing_icon2.png") },
        { id: "1", name: "重度预警", number: "", src: require("../assets/images/all/qingduyujing_icon3.png") }
      ],

      name: "", //桥梁搜索
      data: [
        {
          id: "1",
          path: "/1",
          name: "导航一",
          parentId: "0",
          icon: "el-icon-menu",
          children: [
            {
              id: "11",
              path: "/1/1",
              name: "导航1-1",
              icon: "el-icon-menu"
            },
            {
              id: "12",
              path: "/1/2",
              name: "导航1-2",
              icon: "el-icon-menu"
            }
          ]
        },
        {
          id: "2",
          path: "/2",
          name: "导航二",
          icon: "el-icon-menu",
          parentId: "0",
          children: [
            {
              id: "21",
              path: "/1/1",
              name: "导航1-1",
              icon: "el-icon-menu"
            }
          ]
        },
        {
          id: "3",
          path: "/1",
          name: "导航一",
          parentId: "0",
          icon: "el-icon-menu",
          children: [
            {
              id: "31",
              path: "/1/1",
              name: "导航1-1",
              icon: "el-icon-menu"
            },
            {
              id: "32",
              path: "/1/2",
              name: "导航1-2",
              icon: "el-icon-menu"
            }
          ]
        }
      ], //桥梁树形

      defaultProps: {
        children: "list",
        label: "name"
      },
      currentNodeKey: "", //默认选中
      win: "0", //1显示  0不显示   桥梁搜索
      bottom: "1", //1显示 0不显示
      win1: "0", //1显示， 0不显示   监视器

      // 视频列表数据
      src: "",
      videoList: [],
      closeVideo: "0", //弹出视频窗口
      value: "", //视频选中
      options: [], //视频树
      timer: null,
      closeShow: "0", //曲线窗口
      qiaoType: "0", //桥梁类型  0 1

      // 请求数据定义
      // warnList:'',  //告警数据
      all: "1", //是否显示总览  0bom   1总览

      aloneType: "0", //单桥的类型弹窗
      aloneData: [],
      aloneTypeArr: [], //单桥类型选中

      aloneWin: "0", // 0不显示 1 显示 单桥弹框
      targetBox: "0", //0不显示 2显示 单桥触摸弹框

      srcPath: "", //正式
      // srcPath:'https://www.thingjs.com/pp/4ace010ee17c0cb185ee8dcd',   //测试
      videoToken: "",
      projectName: ""
    };
  },
  destroyed() {
    window.removeEventListener("message", this.postMessageFun, false); //结束监听gis
    // clearInterval(this.timer)
  },
  //创建
  created() {
    this.srcPath = this.srcPATH; //-----------*********读取gis配置文件

    this.$bus.$on("largeWin", this.largeWin); //接收放大的命令
    this.$bus.$on("centerTarget", this.centerTarget); //从cloneLook转过来的id号

    var all = sessionStorage.getItem("all");
    this.projectName = sessionStorage.getItem("name");

    if (all == 0) {
      console.log("进入单桥 监听");
      this.all = 0;
      this.qiaoType = 0; //隐藏总览类型
      //  this.aloneType = 1    //显示单桥类型
      //  this.aloneTypeData();   //获取单桥类型     //----
      this.aloneWarn(); //单桥告警统计
      this.aloneTypeData(); //单桥类型

      this.$bus.$emit("all", "0");
      sessionStorage.setItem("all", 0);
    } else {
      this.all = 1;
      this.$bus.$emit("all", "1");
      sessionStorage.setItem("all", 1);
    }

    this.$nextTick(() => {
      var dom = document.getElementById("gisWin");
      dom.style.left = "300px";
      dom.style.top = "200px";
    });

    setTimeout(() => {
      window.addEventListener("message", this.postMessageFun, false); //监听gis地图
      // window.addEventListener("click",this.aaa, false)      //监听gis地图
    }, 500);
    //  监听桥梁列表点击传过来的事件
    this.$bus.$on("handleNodeClick", this.handleNodeClick);
  },

  //安装
  mounted() {
    this.getToken(); //传递token给gis

    //左侧告警的位置变化
    this.bridgeStatistics(); //桥梁数字统计
    // this.warnNumber(); //告警统计
    this.warnInter();
    this.bridgeTreeData(); //桥梁树形展示
    // this.bridgeWarningData();
    this.bridgeTypyList(); //桥梁类型
    //   this.aloneTypeData();      //获取单桥类型

    sessionStorage.setItem("all", this.all);
  },

  //

  methods: {
    // 查看桥预警状态
    checkWarningBrige() {
      console.log("warningSelect", this.warningSelect);
      this.warningSelect = this.warningSelect == 1 ? 0 : 1;
    },
    // 点击预警显示弹窗
    showAloneLook() {
      console.log(this.$refs);
      this.$refs.alonelookzhw.tab(4);
    },

    // 萤石云视频
    getVideo() {
      this.tokenData();

      setTimeout(() => {
        let arr = [
          { src: "ezopen://open.ys7.com/G14450541/1.live" } // 朔黄桥梁视频
        ];

        var player1 = new EZUIKit.EZUIKitPlayer({
          id: "myVideo", // 视频容器ID
          accessToken: this.videoToken,
          url: arr[0].src,
          width: "100%",
          height: 340
        });
        player1.play();
      }, 500);

      // arr.map((item, index)=>{
      //     var player = new EZUIKit.EZUIKitPlayer({
      //         id: 'myVideoList', // 视频容器ID
      //         accessToken: this.videoToken,
      //         url: item.src,
      //         width: 300,
      //         height: 240
      //     })
      //     player.play();
      // })
    },
    tokenData() {
      console.log("获取token");
      var data = "appKey=a2f0529eddfc4726bc46ab78b9b15609&appSecret=46f68bd6322b7ea2ac11b7843715c389";
      this.$axios
        .post(`https://open.ys7.com/api/lapp/token/get?${data}`)
        .then((res) => {
          console.log("获取token", res.data.data);
          this.videoToken = res.data.data.accessToken;
        })
        .catch((err) => {});
    },

    // 给gis传递token
    getToken() {
      var token = sessionStorage.getItem("token");
      // console.log("token", token);
      // setTimeout(()=>{
      let OIframe = document.getElementById("ditu"); //监听gis
      let childDomain = this.srcPath;
      console.log("allview", token, OIframe, childDomain);
      OIframe.contentWindow.postMessage({ gis_token: token }, childDomain);
      // }, 2000)
    },

    // 获取信息
    getData(id) {
      var data = {
        bid: id
      };
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data))
        .then((res) => {
          console.log("桥梁信息", res.data.data);
          this.$store.commit("getProjectName", res.data.data.bridge_name);
          sessionStorage.setItem("projectName", res.data.data.bridge_name);

          this.$store.commit("getProjectCompay", res.data.data.compayName); //桥梁公司名称  getProjectCompay
          sessionStorage.setItem("projectCompay", res.data.data.compayName); //桥梁公司名称  compayName

          this.$store.commit("getProjectId", id); //项目id发送
          sessionStorage.setItem("projectId", id);
          this.projectId = id;

          this.$bus.$emit("getProjectId", id);
        })
        .catch((err) => {});
    },

    //监听gis  传过来的数据
    postMessageFun(e) {
      // 隐藏
      // 监听gis模型渲染完成
      if (e.data.gis_token) {
        console.log("gis_token", e.data.gis_token);
        this.typeAllFlag = true;
        this.getToken();
        return;
      }

      // 摄像机位置移动
      if (e.data.moveQL) {
        console.log("e.data.moveQL-- 摄像机位置更新", e.data.moveQL);
        // if (this.$refs['aloneWin'].timer) {
        //   // clearInterval(this.$refs['aloneWin'].timer)
        //   this.$refs['aloneWin'].clearInter()
        // }
        this.aloneWin = 0;
      }

      // 触摸测点的弹框
      if (e.data.h_target && e.data.h_evX) {
        console.log("测点触摸");
        this.targetBox = 1; //显示弹框
        this.$bus.$emit("targetHover", e.data.h_target); //触摸传感器，获取到id
        var dom = document.getElementById("targetBox");
        dom.style.left = e.data.h_evX - 230 + "px";
        dom.style.top = e.data.h_evY - 320 + "px";
        return;
      }

      // 飞入园区
      if (e.data.qiaoliang == "ql1") {
        console.log("飞入------------");
        return;
      }

      if (e.data.entryQL) {
        console.log("进入单桥111111111111", e.data.entryQL);
        this.projectId = e.data.entryQL;

        // 进入单桥，桥梁列表菜单隐藏
        this.$bus.$emit("handleClose");
        this.warnClearInter();
        // this.projectId = '10008';
        // sessionStorage.setItem('projectId', this.projectId)
        this.targetBox = 0; //隐藏测点详情
        this.aloneWin = 0;
        this.all = 0;
        this.win = 0;
        // this.qiaoType =0;       //关闭总览的类型弹框            // 单桥隐藏
        // this.aloneWarn();       //单桥告警统计
        // this.aloneTypeData();   //获取单桥类型  //---
        // this.targetBox();       //关闭gis模型测点弹框
        return;
      }

      if (e.data.leaveQL) {
        if (this.$refs["alonelookzhw"]) {
          this.$refs["alonelookzhw"].getClearRate();
        }
        console.log("离开单桥", e.data.leaveQ);
        this.aloneWin = 0; //隐藏单桥弹框信息
        this.all = 1;
        this.aloneType = 0; //单桥类型
        // this.warnNumber(); //总览告警
        this.warnInter();
        //  let OIframe=document.getElementById("ditu");    //监听gis
        // let childDomain="https://www.thingjs.com/pp/b11aea19e425d75509af50ad"
        // OIframe.contentWindow.postMessage({gis: 'qltype', id: this.typeAll}, childDomain)
        return;
      }

      // 点击测点
      if (e.data.target == "target1") {
        console.log("获取测点id");
        this.closeShow = 1; //打开数据曲线窗口
        return;
      }

      // gis上点击传感器   获取id
      if (e.data.c_target) {
        console.log("c_target", e.data.c_target);
        this.$bus.$emit("getTargetId", e.data.c_target); //发送测点id
        this.closeShow = 1; //打开数据曲线窗口
        // 打开数据弹框 隐藏详情弹框
        this.targetBox = 0; //隐藏测点详情
        this.aloneWin = 0;
        return;
      }

      // // 点击gis上桥梁图标
      // if( e.data.qlmessages ){   //== 'QL1'
      //     console.log('点击gis上桥梁图标');
      //     var e = window.event;
      //     var scrollX = e.clientX ;
      //     var scrollY = e.clientY;
      //     console.log('scrollX, scrollY', scrollX, scrollY);
      //   return
      // }

      // 触摸gis上桥梁图标
      if (e.data.h_evX) {
        var projectId = e.data.h_qlmessages;
        console.log("projectId", projectId);
        // sessionStorage.setItem("projectId", projectId);
        // sessionStorage.setItem("mouseProjectId", projectId);
        this.typeAllFlag = true;
        this.$bus.$emit("getProjectId", projectId); //发送给触摸框id， 通过id获取呢日哦你给
        console.log("位置xy", e.data.h_evX, e.data.h_evY);
        this.aloneWin = 1; //显示单桥
        var dom = document.getElementById("aloneWin");
        dom.style.left = e.data.h_evX - 222 + "px";
        dom.style.top = e.data.h_evY - 426 + "px";
        console.log("鼠标移入桥标点", dom);
        return;
      }

      // 点击-- 进入单桥模型
      if (e.data.c_qlmessages) {
        var id = e.data.c_qlmessages;
        console.log("桥梁___id", id);
        this.all = 0;
        this.gisData("flytoql1");
        this.$store.commit("getProjectId", id); //写入状态10008
        this.warnClearInter();
        this.getData(id); //进入单桥 获取桥梁信息   获取桥梁id， 桥梁名称等信息   记住呀。。。
        sessionStorage.setItem("projectId", id);
        return;
      }
    },

    // gis重定向，  放大，缩小
    gisData(name) {
      console.log("重定向---------------all");

      // 重定向的时候 会显示为总览
      // if(name == 'resetFly'){
      //     }
      // this.all = 1;

      this.targetBox = 0; //隐藏测点信息弹框
      let OIframe = document.getElementById("ditu"); //监听gis
      let childDomain = this.srcPath;
      OIframe.contentWindow.postMessage({ gis: name }, childDomain);
    },

    //从aloneLook 传过来的id值
    centerTarget(val) {
      console.log("id", val);
      let fixedpos = "fixedpos";
      let OIframe = document.getElementById("ditu"); //监听gis
      let childDomain = this.srcPath;
      OIframe.contentWindow.postMessage({ gis: fixedpos, id: val }, childDomain);
    },

    // 树选中
    handleNodeClick(data) {
      // this.loadingPage.close();
      console.log("树选中", data);
      var id = data.id;
      this.gisData("flytoql1"); //飞入模型
      // 获取桥梁信息
      this.getData(data.id);
      this.dingwei(data.id); //选中树，定位桥梁位置
    },

    // 桥梁定位
    dingwei(id) {
      console.log("桥梁--id", id);
      let bridgefixedpos = id;
      let OIframe = document.getElementById("ditu"); //监听gis
      let childDomain = this.srcPath;
      OIframe.contentWindow.postMessage({ gis: "bridgefixedpos", id: bridgefixedpos }, childDomain);
    },

    //gis  按类型查询桥梁类型
    changeType(val) {
      // console.log('val 选择桥梁类型', val);
      if (this.typeAll.includes(val.tid) == true) {
        for (var i = 0; i < this.typeAll.length; i++) {
          if (this.typeAll[i] == val.tid) {
            this.typeAll.splice(i, 1);
          }
        }
      } else {
        this.typeAll.push(val.tid);
      }

      let OIframe = document.getElementById("ditu"); //监听gis
      let childDomain = this.srcPath;
      OIframe.contentWindow.postMessage({ gis: "qltype", id: this.typeAll }, childDomain);
    },

    // gis 监测类型
    getType(item) {
      // console.log('item', item);
      // aloneTypeArr
      if (this.aloneTypeArr.includes(item.id) == true) {
        for (var i = 0; i < this.aloneTypeArr.length; i++) {
          if (this.aloneTypeArr[i] == item.id) {
            this.aloneTypeArr.splice(i, 1);
          }
        }
      } else {
        this.aloneTypeArr.push(item.id);
      }
      // console.log('aloneTypeArr', this.aloneTypeArr);
      // visiblepoint
      let OIframe = document.getElementById("ditu"); //监听gis
      let childDomain = this.srcPath;
      OIframe.contentWindow.postMessage({ gis: "visiblepoint", id: this.aloneTypeArr }, childDomain);
    },

    // u3d 设备详情
    DeviceDetailFromUnity(e) {
      console.log("e_u3d", e);
    },
    // u3d 视频列表
    VideoListFromUnity(e) {
      console.log("e_u3d", e);
    },
    // u3d 视频详情
    // DeviceDetailFromUnity(e){
    //     console.log('e_u3d',e);
    // },
    // gisData1(){

    // },

    //  关闭曲线弹框
    closeWin() {
      if (this.closeShow == 1) {
        this.closeShow = 0;
      } else {
        this.$nextTick(() => {
          this.closeShow = 1;
        });
      }
      // console.log('closeShow', this.closeShow);
    },
    closeWin1() {
      this.$bus.$emit("changeModel", "2");
      // this.$router.push('/model3D')
    },
    changeAll() {
      // console.log(`all`, this.all)
      if (this.all == 0) {
        if (this.$refs["alonelookzhw"]) {
          this.$refs["alonelookzhw"].getClearRate();
        }
        this.all = 1;
        // 单桥隐藏
        this.aloneType = 0; //单桥类型
        // this.warnNumber(); //总览告警
        this.warnInter();
      } else {
        //进入单桥
        this.all = 0;
        this.qiaoType = 0; //关闭总览的类型弹框
        // this.aloneTypeData();   //获取单桥类型  //--
        // this.aloneWarn(); //单桥告警统计
        this.warnClearInter();
      }
    },
    // 放大缩小曲线窗口
    largeWin(e) {
      var Dom = document.getElementById("largeWin");
      if (e == 1) {
        Dom.style.width = "100%";
        Dom.style.marginLeft = "0px";
        Dom.style.left = "0px";
      } else {
        Dom.style.width = "1326px";
        Dom.style.marginLeft = "-663px";
        Dom.style.left = "50%";
      }
    },

    // 子传过来的
    getAdd(e) {
      this.closeShow = e; //关闭图表窗口
    },
    getAdd1(e) {
      this.closeVideo = e; //关闭视频窗口
    },

    typeShow() {
      if (this.all == 1) {
        if (this.qiaoType == 0) {
          this.qiaoType = 1;
        } else {
          this.qiaoType = 0;
        }
      } else {
        if (this.aloneType == 1) {
          this.aloneType = 0;
        } else {
          this.aloneType = 1;
        }
        // console.log('this.aloneType', this.aloneType);
      }
    },

    handleClose(e) {
      console.log("点击空白处");
      this.win = 0; //隐藏桥梁弹框
      if (this.win1 == 1) {
        var player = videojs("myVideo");
        player.dispose();
        this.videoList.forEach((item, index) => {
          let player = videojs("myVideoList" + index);
          player.dispose(); //dispose销毁
        });
        this.win1 = 0;
      }
    },

    // 是否显示搜索窗口
    searchShow() {
      console.log("视频");

      if (this.win1 == 1) {
        this.tvShow();
      }
      setTimeout(() => {
        if (this.win == 0) {
          this.win = 1;
        } else {
          this.win = 0;
        }
      }, 200);
    },
    // 监视器是否显示
    tvShow() {
      // if(this.win==1)this.win=0;
      if (this.win == 1) {
        this.win = 0;
      }

      // "bid=10008&videoName=null" "http://114.116.93.53:8197/pandect/select/bridge/video"

      // "videoName=11" "http://114.116.93.53:8197/pandect/select/video"     总览视频

      // setTimeout(()=>{
      if (this.win1 == 0) {
        if (this.all == "1") {
          var path = `pandect/select/video`;
          console.log("总览的视频");
        } else {
          console.log("单桥的视频");
          var path = `pandect/select/bridge/video?bid=${this.projectId}&videoName=`;
        }

        console.log("打开");
        this.videoData(path); //获取视频数据
        this.win1 = 1;

        this.getVideo(); //获取萤石云视频
      } else {
        console.log("关闭视频窗口");
        if (this.videoList.length == 0) {
          this.win1 = 0;
          return;
        }

        console.log("关闭");
        this.win1 = 0;
        var player = videojs("myVideo");
        player.dispose();
        this.videoList.forEach((item, index) => {
          let player = videojs("myVideoList" + index);
          player.dispose(); //dispose销毁
        });
      }
      // this.all = 1;
      // }, 200)
    },

    // 修改主视频播放
    changeVideo(item) {
      console.log("item", item);
      this.src = item.videoUrl;
      var player = videojs("myVideo");
      videojs("myVideo").src(this.src);
      player.play();
    },

    // 隐藏搜索窗口主体内容
    winBottom() {
      if (this.bottom == 0) {
        this.bottom = 1;
      } else {
        this.bottom = 0;
      }
    },

    // 打开视频弹框窗口
    videoWin() {
      this.tvShow(); //关闭视频
      this.closeVideo = 1; //打开视频弹框  等于1的时间
      // var src = this.src;
      // if( this.closeVideo ==1){
      //      this.closeVideo = 0;
      // }else{
      //      this.closeVideo = 1;
      // }
    },

    changeTarget(item) {
      console.log("item", item);

      this.value = item.videoName; // id
      this.src = item.videoUrl;

      this.tvShow(); //关闭视频
      this.closeVideo = 1; //弹出窗口

      // 关闭主视频
      //  console.log('关闭');
      // var player  = videojs('myVideo');
      // player.dispose();
      // // 重新打开新的视频
      // this.$nextTick(()=>{
      //     // this.src = item.videoUrl;    //播放主视频
      //     var player1  = videojs('myVideo');
      //     player1.play();
      // })
    },

    // 数据请求-------------------------------数据请求-----------------------数据请求
    warnInter() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.warnNumber();
      this.timer = setInterval(() => {
        this.warnNumber();
      }, 10000);
    },
    warnClearInter() {
      if (this.timer) {
        console.log("清除定时器");
        clearInterval(this.timer);
      }
    },
    //总览告警
    warnNumber() {
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge/warn/status`)
        .then((res) => {
          console.log("告警统计", res);
          var data = res.data.data;
          this.warnList[2].number = data.count.oneWarnCount;
          this.warnList[1].number = data.count.twoWarnCount;
          this.warnList[0].number = data.count.threeWarnCount;

          this.warningData = [];
          let arr = [];
          console.log("data.list", data.list);
          for (let i in data.list) {
            console.log("i", i);
            switch (i) {
              case "threeWarnList":
                arr.push(warnListObj("轻度预警", data.list[i]));
                break;
              case "twoWarnList":
                arr.push(warnListObj("中度预警", data.list[i]));
                break;
              case "oneWarnList":
                arr.push(warnListObj("重度预警", data.list[i]));

                break;
            }
          }

          this.warningData = JSON.parse(JSON.stringify(arr));
          console.log("warningData", this.warningData);
        })
        .catch((err) => {});
    },

    // 桥梁统计
    bridgeStatistics() {
      this.$axios
        .get(`${this.baseURL}pandect/select/bridgegroup/count`)
        .then((res) => {
          //  console.log('桥梁数字统计', res);
          // numberList    data: cas: 0 count: 1 ger: 0 length: 600 sus: 1    tar: 0
          var data = res.data.data;
          this.numberList[0].name = "悬索桥（座）";
          this.numberList[0].number = data.sus;
          this.numberList[1].name = "斜拉桥（座）";
          this.numberList[1].number = data.cas;
          this.numberList[2].name = "梁桥（座）";
          this.numberList[2].number = data.ger;
          this.numberList[3].name = "拱桥（座）";
          this.numberList[3].number = data.tar;
          this.numberAll.count = data.count;
          this.numberAll.length = data.length;
        })
        .catch((err) => {});
    },
    // 桥梁树形
    bridgeTreeData() {
      this.$axios
        .post(`${this.baseURL}pandect/select/likename?name=${this.name}`)
        .then((res) => {
          //  console.log('桥梁树形信息', res);
          var data = res.data.data;
          this.data = [];
          data.forEach((item, index) => {
            item.name = item.lineName;
            item.parentId = 0;
            item.list.forEach((item1, index1) => {
              item1.parentId = 1;
            });
          });

          let projectId = sessionStorage.getItem("projectId");
          if (projectId) {
            this.getData(projectId);
          } else {
            this.getData(data[0].list[0].id);
          }

          setTimeout(() => {
            this.data = data;
            console.log("dat桥梁树zhwa", this.data);
          }, 200);
        })
        .catch((err) => {});
    },

    // 设置默认桥梁

    getData(id) {
      console.log("获取信息");

      var data = {
        bid: id
      };
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data))
        .then((res) => {
          console.log("桥梁信息", res.data.data);

          this.$store.commit("getProjectName", res.data.data.bridge_name);
          sessionStorage.setItem("projectName", res.data.data.bridge_name);

          this.$store.commit("getProjectCompay", res.data.data.compayName); //桥梁公司名称  getProjectCompay
          sessionStorage.setItem("projectCompay", res.data.data.compayName); //桥梁公司名称  compayName

          this.$store.commit("getProjectId", id); //项目id发送
          sessionStorage.setItem("projectId", id);
          this.projectId = id;

          this.$bus.$emit("getProjectId", id);
        })
        .catch((err) => {});
    },

    // warningData   桥预警列表

    bridgeWarningData() {
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge/warn/status`)
        .then((res) => {
          console.log("桥梁预警列表信息", res);
          var data = res.data.data;
          this.warningData = [];
          let arr = [];
          console.log("data.list", data.list);
          for (let i in data.list) {
            console.log("i", i);
            switch (i) {
              case "threeWarnList":
                arr.push(warnListObj("轻度预警", data.list[i]));
                break;
              case "twoWarnList":
                arr.push(warnListObj("中度预警", data.list[i]));
                break;
              case "oneWarnList":
                arr.push(warnListObj("重度预警", data.list[i]));

                break;
            }
          }

          this.warningData = JSON.parse(JSON.stringify(arr));
          console.log("warningData", this.warningData);
        })
        .catch((err) => {});
    },

    // 桥梁类型
    bridgeTypyList() {
      this.$axios
        .post(`${this.baseURL}pandect/select/bridgetype/list`)
        .then((res) => {
          console.log("桥梁类型", res.data.data);
          var data = res.data.data;

          this.queryType = data;

          var arr = [];
          this.queryType.forEach((item, index) => {
            arr.push(item.tid);
          });
          this.typeAll = arr;
          // 默认选中第一座桥
          // this.getData('10008');       //不确定影响
        })
        .catch((err) => {});
    },

    // 视频请求
    videoData(path) {
      // if(this.all = 0){
      //     var name = '培森';
      // }else{
      //     var name = ''
      // }
      // pandect/select/video?videoName=${this.name}
      this.$axios
        .post(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("获取视频数据", res.data.data);
          //  this.videoList = res.data.data;
          var data = res.data.data;
          var arr = [];
          data.forEach((item, index) => {
            var num = item.list;
            num.forEach((item1, index1) => {
              arr.push(item1);
            });
          });
          this.videoList = arr;

          console.log("this.videoList", this.videoList);

          this.src = this.videoList[0].videoUrl; //播放主视频
          this.$nextTick(() => {
            var player = videojs("myVideo");
            player.play();
          });
          this.videoList.forEach((item, index) => {
            //遍历播放播放多个视频
            this.$nextTick(() => {
              let player = videojs("myVideoList" + index);
              player.play();
            });
          });

          this.options = data; //视频搜索下拉框
        })
        .catch((err) => {});
    },

    // 单桥 类型
    aloneTypeData() {
      // this.$axios.get(`${this.baseURL}synthesis/target/type`).then(res => {       //全部类型
      this.$axios
        .get(`${this.baseURL}synthesis/project/target/type/${this.projectId}`)
        .then((res) => {
          this.aloneData = res.data.data;
          this.aloneData.push({ id: "99", name: "安防监控", list: [{ id: "1", name: "视频监控", icon: "icon-shipinjiankong_white_icon1" }] });

          var arr = [];
          this.aloneData.forEach((item, index) => {
            item.list.forEach((item, index) => {
              arr.push(item.id);
            });
          });
          this.aloneTypeArr = arr; //选中所有
        })
        .catch((err) => {});
    },
    //单桥告警统计
    aloneWarn() {

      this.$axios
        .post(`${this.baseURL}pandect/select/bridge?bid=${this.projectId}`)
        .then((res) => {
          //  this.aloneData = res.data.data;
          var data = res.data.data.warnCount;
          this.warnList[2].number = data.oneWarnCount;
          this.warnList[1].number = data.twoWarnCount;
          this.warnList[0].number = data.threeWarnCount;
        })
        .catch((err) => {});
    },
    // 获取单桥传过来的信息
    getAloneWarn(data) {
      this.warnList[2].number = data.oneWarnCount;
      this.warnList[1].number = data.twoWarnCount;
      this.warnList[0].number = data.threeWarnCount;
      let OIframe = document.getElementById("ditu"); //监听gis
      let childDomain = this.srcPath;
      OIframe.contentWindow.postMessage({ gis: "gislist_Updata", data: data.gis }, childDomain);
    }
  }
};
</script>
<style scoped lang="scss">
.allView {
  // width: 100%;
  // height: 100%;
  // position: absolute;
  // top: 0;
  // z-index: 1;
}

.main {
  width: 100%;
  // height: 100%;
}

.leftTop1 {
  /* width: 11%; */
  width: 152px;
  height: 564px;
  background: rgba($color: #041742, $alpha: 0.7);
  border-radius: 8px;
  border: 1px solid;
  border-image: linear-gradient(300deg, rgba(155, 169, 203, 1), rgba(61, 107, 208, 1)) 1 1;
  position: absolute;
  top: 158px;
  left: 24px;
  z-index: 1;
  padding: 26px 17px;

  .left1 {
    padding-bottom: 10px;
    border-bottom: 1px dashed #3a4a7b;
  }
}

.leftTop2 {
  position: absolute;
  left: 20px !important;
  bottom: 25px;
  z-index: 2;

  li {
    width: 160px;
    height: 40px;
    background: rgba($color: #04153f, $alpha: 0.6);
    border-radius: 20px;
    margin-bottom: 16px;
  }
}

.leftTop22 {
  position: absolute;
  left: 500px;
  bottom: 50px;
  z-index: 2;

  li {
    width: 160px;
    height: 40px;
    background: rgba($color: #04153f, $alpha: 0.6);
    border-radius: 20px;
    margin-bottom: 16px;
  }
}

.left2 {
  margin-top: 22px;

  ul li {
    margin-bottom: 25px;
  }

  ul li:last-child {
    margin-bottom: 0px;
  }
}

// 搜索
.search {
  position: absolute;
  top: 158px;
  right: 24px;
  z-index: 2;
  // width:56px;
  // height: 56px;
  // border-radius: 28px;
  // background: #04153F;
}

// 放大 缩小 搜索
.operation {
  position: absolute;
  top: 220px;
  right: 24px;
  z-index: 1;

  .maxmin {
    margin-top: 8px;
    padding-top: 18px;
    width: 56px;
    height: 156px;
    border-radius: 28px;
    background: rgba($color: #04153f, $alpha: 0.6);
  }

  .yuan {
    // text-align: center;
    // border-radius: 28px;
    // width: 33px;
    // height: 33px;
    // line-height: 33px;
    // background: rgba($color: #CCDAFF, $alpha: 0.2);
  }

  .yuan1 {
    text-align: center;
    border-radius: 28px;
    width: 33px;
    height: 33px;
    line-height: 33px;
    background: rgba($color: #ccdaff, $alpha: 0.2);
  }
}

// 桥梁查询
::v-deep .warningzhw {
  position: absolute;
  right: -328px;
  bottom: 0px;
  z-index: 1;
  width: 318px;
  height: 300px;
  overflow: auto;
  border-radius: 8px;
  border: 1px solid #0c49af;
  background: rgba($color: #04153f, $alpha: 0.7);

  .winTitle {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .winTitle1 {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .fu {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    .zi {
      position: absolute;
      right: 28px;
      top: 24px;
      padding-left: 12px;
      border-left: 1px solid #3a4a7b;
    }

    .el-input {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #2667db;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    div.el-input .el-input__inner:hover,
    div.el-input .el-input__inner:focus {
      border-radius: 20px !important;
      border: 1px solid #2667db;
    }

    div.el-input .el-input__inner {
      border-radius: 20px !important;
    }
  }

  .winBottom {
    // padding-left: 15px;
    // padding-right: 15px;
  }
}

// 桥梁查询
::v-deep .win {
  position: absolute;
  right: 89px;
  top: 113px;
  z-index: 1;
  width: 318px;
  border-radius: 8px;
  border: 1px solid #0c49af;
  background: rgba($color: #04153f, $alpha: 0.7);

  .winTitle {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .winTitle1 {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .fu {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    .zi {
      position: absolute;
      right: 28px;
      top: 24px;
      padding-left: 12px;
      border-left: 1px solid #3a4a7b;
    }

    .el-input {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #2667db;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    div.el-input .el-input__inner:hover,
    div.el-input .el-input__inner:focus {
      border-radius: 20px !important;
      border: 1px solid #2667db;
    }

    div.el-input .el-input__inner {
      border-radius: 20px !important;
    }
  }

  .winBottom {
    // padding-left: 15px;
    // padding-right: 15px;
  }
}

::v-deep .win1 {
  position: absolute;
  right: 89px;
  width: 494px;
  top: 150px;
  z-index: 10;
  border-radius: 8px;
  border: 1px solid #0c49af;
  background: rgba($color: #000000, $alpha: 0.4);

  .winTitle {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .videoBox {
    // margin-left: 5px;
    // margin-right: 5px;
    width: 100%;
    height: 270px;
    object-fit: fill;

    .myVideo-dimensions {
      width: 100%;
      height: 270px;
    }
  }

  .videoBox1Fu {
    width: 100%;
    padding: 8px 5px 10px 5px;
  }

  .video {
    width: 160px !important;
    height: 90px !important;
  }

  .videoBox1 {
    width: 160px;
    height: 90px;
    margin-bottom: 10px;

    .myVideo-dimensions {
      width: 160px;
      height: 90px;
    }
  }

  .el-select {
    width: 100%;
    height: 40px;
    border-radius: 20px;

    .el-input {
      height: 40px;
    }

    .el-input__inner {
      border-radius: 20px !important;
    }
  }
}

// 类型查询
.listSearch {
  position: absolute;
  bottom: 32px;
  right: 32px;
  z-index: 1;
  // width: 40px;
  // height: 40px;
  // background: rgba($color: #04153F, $alpha: 0.6);
  // border: 2px solid rgba(238, 238, 238, 0.66);
  // border-radius: 28px;
}

// 地图2d和3d切换
.changeDitu {
  position: absolute;
  bottom: 92px;
  right: 32px;
  z-index: 1;
}

// 图表弹框
.dataWin {
  position: absolute;
  width: 1326px;
  height: 575px;
  left: 50%;
  margin-left: -663px;
  top: 50%;
  margin-top: -262px;
  z-index: 1;
}

.qiaoType {
  position: absolute;
  bottom: 30px;
  right: 100px;
  z-index: 1;

  .bottom {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
  }

  .ul1 {
    .ul1Title {
      letter-spacing: 0px;
      color: #ccdaff;
    }

    li {
      margin-right: 26px;
      // width: 28px;
      // height: 28px;

      border: none !important;
    }

    li:nth-child(4) {
      margin-right: 0;
    }
  }

  .acLi {
    // width: 28px;
    // height: 28px;
    text-align: center;
    // height: 28px;
    background: #0c49af !important;
    border-radius: 28px;
  }

  .li1 {
    width: 28px;
    height: 28px;
    text-align: center;
    // height: 28px;
    background: #3d4d6e;
    border-radius: 28px;
  }
}

// .top{
//     padding-left: 24px;
//     padding-right: 24px;
//     height: 50px;
//     line-height: 40px;
//     background: linear-gradient(135deg, #002F83 0%, rgba(0, 19, 59, 0.5) 100%);
//     border-radius: 8px 8px 2px 2px;
//     border: 1px solid;
//     border-image: linear-gradient(138deg, rgba(17, 102, 255, 1), rgba(0, 0, 0, 0.5)) 1 1;
// }
// .bottom{
//     padding-left: 24px;
//     padding-right: 24px;
//     padding-bottom: 24px;
//     background: #04153F;
//     border-radius: 2px;
//     border: 1px solid;
//     border-image: linear-gradient(119deg, rgba(17, 102, 255, 1), rgba(0, 50, 175, 0.95)) 1
// }

// 视频弹框
.videoWin {
  position: absolute;
  width: 1184px;
  height: 585px;
  z-index: 1;
  left: 50%;
  margin-left: -592px;
  margin-top: -262px;
  top: 50%;
}

.a {
  z-index: 99;
}

.b {
  z-index: -1;
}

#ditu {
  width: 100%;
  height: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 0;
}

// 单桥类型弹窗
.aloneType {
  position: absolute;
  bottom: 20px;
  right: 104px;
  width: 354px;
  z-index: 2;

  .itemBox {
    width: 70px;
    // margin-right: 28px;
    margin-bottom: 10px;
    letter-spacing: 0px !important;
  }

  .itemBox1 {
    width: 28px;
    height: 28px;
    text-align: center;
    height: 28px;
    background: #3d4d6e;
    border-radius: 28px;
  }

  .itemBox2 {
    width: 28px;
    height: 28px;
    text-align: center;
    height: 28px;
    background: #0c49af;
    border-radius: 28px;
  }

  .itemBox:nth-child(5n) {
    margin-right: 0 !important;
  }

  .top {
    width: 100%;
  }

  .bottom {
    border: none !important;
    padding: 11px 0px !important;
  }

  .bottom_1 {
    border: none !important;
  }
}

#gisWin {
  position: absolute;
  z-index: 9;
}

// 单桥信息框
#aloneWin {
  position: absolute;
  width: 456px;
  // height: 380px;
  left: 700px;
}

.ztop {
  z-index: 1;
}

.zbottom {
  z-index: -999;
}

#targetBox {
  position: absolute;
  width: 456px;
  // height: 380px;
  left: 700px;
}

.noVideo {
  padding-top: 13%;
  height: 300px;
  text-align: center;

  img {
    width: 92px;
    height: 80px;
  }
}

.site9 {
  margin-left: -270px;
}

.kuandu {
  .gong {
    font-size: 10px;
  }
}
</style>
