<template>
  <div @mouseenter="mouseEnter" :class="menuTwo == 0 ? 'menuShow1' : 'menuShow'">
    <!-- 更多弹框  @mouseleave="mouseLeave"  -->

    <!-- <div style="color: var(--themeColor); border: 1px solid var(--mytest)" @click="addColor('blue')">sssssss</div> -->

    <div v-if="gengduoWin == 1" class="gengduo">
      <div v-for="(item, index) in moreNav" :key="index">{{ item.name }}</div>
    </div>
    <div class="box nav-bg">
      <div class="loginTile" :style="{ width: mainNavWidth + 'px' }" v-if="mainNavWidth">
        <img :src="imgSrc" alt="" />
        <span>{{ systemName }}</span>
      </div>
      <div class="title box center justify flex1" :style="{ paddingLeft: mainNavWidth + 'px' }">
        <!-- 一级导航  style="flex: 1" -->
        <div class="box">
          <!--   @mouseover="getAll"-->
          <!-- <div class="box around box_column mR20 pointer noFlex" @click="getAll"> -->
          <div class="box around box_column pointer noFlex" @click="getAll">
            <!-- allTitle -->
            <!-- <div class="fs22 title1 zonglan" :class="active == '-8' ? 'activeColor' : ''"
              style="height: 83px; width: 100%; padding: 0 24px; line-height: 83px">总览</div> -->
          </div>
          <ul class="flex1 box ul center" @mouseenter="userAdmin = false">
            <!-- @click="getNav(item.id)"  @mouseover="getNav(item.id)"-->
            <li @click="getNav(item)" v-for="(item, index) in navList" :key="index" class="pointer box around" style="position: relative" :class="active == item.id ? 'activeColor' : ''">
              <!-- <el-divider direction="vertical"></el-divider> -->
              <div class="box center">
                <div class="titleBox">
                  <div class="textC title1 zonglan" style="font-size: 20px">
                    {{ item.name }}
                    <!-- <span class="nav-r-icon">
                    </span> -->
                  </div>
                  <div v-show="active == item.id" class="border-div"></div>
                  <!-- <div class="fs10 textC title2">{{ item.eng }}</div> -->
                </div>
                <!-- <div class="you"></div> -->
              </div>
              <el-divider v-if="index < navList.length - 1" class="my-divider" direction="vertical"></el-divider>
            </li>
          </ul>
          <div class="box center" @click="gengduoShow" v-if="gengduoWin == 1">
            <img src="../../assets/images/all/gengduo_icon.png" alt="" />
          </div>
        </div>
        <!-- 用户名称 v-show="data.length > 1"-->
        <div class="box center show-select">
          <div class="nav-icon" @click="handleNavSub" v-show="subnav.length">
            <!-- <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
              <el-submenu index="2">
                <template slot="title">icon</template>
                <el-menu-item index="2-1">选项1</el-menu-item>
                <el-menu-item index="2-2">选项2</el-menu-item>
                <el-menu-item index="2-3">选项3</el-menu-item>
                <el-submenu index="2-4">
                  <template slot="title">选项4</template>
                  <el-menu-item index="2-4-1">选项1</el-menu-item>
                  <el-menu-item index="2-4-2">选项2</el-menu-item>
                  <el-menu-item index="2-4-3">选项3</el-menu-item>
                </el-submenu>
              </el-submenu>
            </el-menu> -->
            <img :src="navSubVis ? iconList[0].active : iconList[0].src" alt="" style="width: 20px; height: 20px" />
            <!-- 副导航 -->
            <ul class="nav-icon-ul" v-if="navSubVis" @mouseout="subNavOut" @mouseleave="navSubVis = false">
              <li class="nav-icon-item" @mouseover="subNavOver(item)" :class="mouseName === item.name ? 'active' : ''" v-for="item in subnav" :key="item.name" @click.stop="handleNavItem(item)" :data-name="item.name" :data-subitem="JSON.stringify(item)">
                <span>{{ item.name }}</span>
                <span style="font-size: 12px" v-if="item.children.length">&gt</span>
                <ul class="nav-icon-sub-ul">
                  <li class="nav-icon-sub-item" v-for="sub in item.children" :key="sub.name" @click.stop="handleNavSubItem(sub, item)">
                    <div class="nav-icon-sub-text" :class="subNavObj.path && subNavObj.path === sub.path ? 'active' : ''" :data-subitem="JSON.stringify(sub)" :data-name="item.name">{{ sub.name }}</div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="shu" v-show="data.length > 1"></div>
          <div>
            <div class="search pointer" @click.stop="searchShow" v-show="data.length > 1">
              <el-tooltip content="桥梁列表" placement="bottom">
                <img :src="winBox == 1 ? iconList[1].active : iconList[1].src" alt="" style="width: 20px; height: 20px" />
              </el-tooltip>
            </div>

            <!-- 下拉桥梁弹框   v-if="winBox == 1"   -->
            <div id="qiaoliang-modal" class="xiala" v-if="winBox == 1"  @mouseleave="winBox = 0">
              <!-- <img src="../../assets/images/img/sousuo1.png" alt="" class="pointer" /> -->
              <div class="win newTree winStyle">
                <div class="winTitle box justify">
                  <div>桥梁列表</div>
                </div>
                <div class="winBottom bottom padd0">
                  <div class="fu">
                    <el-input v-model="name" placeholder="请输入桥梁名称"></el-input>
                    <div class="zi pointer" @click="bridgeTreeData">
                      <img src="../../assets/images/all/sousuo.png" alt="" />
                    </div>
                  </div>
                  <el-tree style="padding: 0 16px" ref="tree" :data="data" :default-expand-all="true" :props="defaultProps" node-key="id" :current-node-key="currentNodeKey" :highlight-current="true" @node-click="handleNodeClick">
                    <span class="custom-tree-node textL" slot-scope="{ node, data }">
                      <span> {{ data.name }} </span>
                      <span v-if="data.parentId == '0'">({{ data.list.length }})</span>
                    </span>
                  </el-tree>
                </div>
              </div>
            </div>
          </div>
          <div class="shu"></div>
          <div class="box center" @mouseenter="userAdmin = true">
            <!-- <img class="mR10" src="../../assets/images/img/yonghuIcon.png" alt="" />
            <div>{{ user }}</div> -->
            <div style="font-size: 16px">
              <span class="el-dropdown-link box center">
                <div class="box center">
                  <img class="mR10" src="../../assets/images/img/yonghuIcon.png" alt="" />
                  <div class="pointer" style="color: #fff">{{ user }}</div>
                </div>
                <i class="el-icon-arrow-down el-icon--right" style="color: #fff"></i>
              </span>

              <!-- <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="isAdmin == 1">
                  <div class="pointer" @click="getSystem">系统管理</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="pointer" @click="back">退出</div>
                </el-dropdown-item>
              </el-dropdown-menu> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <ul class="user-admin" v-if="userAdmin" @mouseleave="userAdmin = false">
      <li v-if="isAdmin == 1" @click="getSystem">系统管理</li>
      <li @click="changePwd">修改密码</li>
      <li @click="back">退出</li>
    </ul>
    <!-- 二级导航  @mouseleave="mouseLeave"-->

    <!-- <div class="line1" v-if="menuTwo == 1 && list.length >= 1"></div> -->
    <div class="menuTwo box justify center my-bg" v-if="menuTwo == 1 && list.length >= 1">
      <!-- <div class="topBr"></div> -->
      <div class="box center menuTwoBox newUi">
        <div class="navImg">
          <!-- <div class="navTitleBox">
            <div class="navTitle">{{ projectCompay }}</div>
            <div class="navTitle">{{ projectName }}</div>
            <div class="navTitle"></div>
          </div> -->
          <!-- <div class="navImg"></div> -->
        </div>
        <!-- <div class="shu1"></div> -->
        <!-- 二级导航 
            background-color="#545c64"-->
        <ul class="box center twoUl" v-if="list.length >= 1">
          <li class="pointer box center" v-for="(item, index) in list" :key="index" @click="go(item)">
            <div class="lifu box center">
              <div style="padding-left: 20px; padding-right: 20px" :class="twoActive == item.id ? 'twoActive2' : 'twoActive1'">{{ item.name }}</div>
              <div class="ulShu" v-if="index != list.length - 1"></div>
              <div class="imgbox" v-if="twoActive == item.id"></div>
            </div>
          </li>
        </ul>
        <!-- <el-menu
            class="menu"
            default-active="1"
            mode="horizontal"
            text-color="#fff"
            active-text-color="#ffd04b"
            :default-openeds="openeds"
            :unique-opened="true"
            >
            <template v-for="item in list">
                <el-submenu v-if="item.children && item.children.length" :index="item.path" :key="item.path"  @click="go(1,item)">
                    <template slot="title"><i :class="item.icon" ></i><span>{{item.name}}</span></template>
                    <template v-for="itemChild in item.children">
                        <el-submenu v-if="itemChild.children && itemChild.children.length" :index="itemChild.path" :key="itemChild.path" >
                            <template slot="title" ><i :class="itemChild.icon" ></i><span>{{itemChild.name}}</span></template>
                        </el-submenu>
                        <el-menu-item v-else :index="itemChild.path" :key="itemChild.path"><i :class="itemChild.icon"></i><span slot="title">{{itemChild.name}}</span></el-menu-item>
                    </template>
                </el-submenu>            
                <el-menu-item v-else :index="item.path"  :key="item.path"><i :class="item.icon"></i><span  slot="title">{{item.name}}</span></el-menu-item>
            </template>
          </el-menu> -->
      </div>
      <img class="img_right" src="../../assets/images/bridge_shadow@2x.png" alt="">
      <!-- <div @click="hideNav" class="tab tab2"></div> -->
    </div>
    <!-- <div class="line2" v-if="menuTwo == 1 && list.length > 1"></div> -->
    <!-- <div class="line2" v-if="menuTwo == 1"></div> -->
    <!-- 修改密码dialog -->
    <div v-if="isShowChangePsdwordDialog">
      <change-pasd-dialog :isShowChangePsdwordDialogSync.sync="isShowChangePsdwordDialog"></change-pasd-dialog>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { BREADCRUMB, ISOPATIONS } from "@/store/const";
import ChangePasdDialog from "../../components/dialog/ChangePasdDialog.vue";
import { addColor } from "../../../addColor";
export default {
  components: {
    ChangePasdDialog
  },
  name: "menuShow",
  inject: ["reload"],
  // props: ["sendData"],
  data() {
    return {
      mouseName: "",
      navSubVis: false,
      subNavObj: { name: "", path: "" },
      subnav: [],
      iconList: [
        {
          name: require("../../assets/img/nav/nav.png"),
          src: require("../../assets/img/nav/nav.png"),
          active: require("../../assets/img/nav/navactive.png")
        },
        {
          name: require("../../assets/img/nav/qiao.png"),
          src: require("../../assets/img/nav/qiao.png"),
          active: require("../../assets/img/nav/qiaoactive.png")
        }
      ],
      activeIndex2: "",
      breadList: [],
      timer: null,
      userAdmin: false,
      user: "",
      navList: [],
      oldNavList: [],
      navTwoList: [],

      // projectName:'', //项目名称
      // projectCompay:'',  //公司名称

      moreNav: [],
      // navThreeList:[
      //   {'id':'1','name':'日常巡检'},{'id':'2','name':'定期检查'},{'id':'3','name':'特性检查'},{'id':'4','name':'载荷试验'},{'id':'4','name':'桥梁养护'},
      // ],

      active: "-8", //导航选中样式
      twoActive: "", //选中二级导航
      list: [],
      menuTwo: "1", //0 隐藏 1 显示 -------------------------
      gengduoWin: "0", // 1显示  0不显示
      openeds: [],

      allView: "", //单独获取总览

      data: [],
      name: "",
      defaultProps: {
        children: "list",
        label: "name"
      },
      currentNodeKey: "", //默认选中

      win: "0", //树形弹框
      winBox: "0", //树形按钮是否显示
      isAdmin: "",
      imgSrc: "", // 图片
      mainNavWidth: "", // 主导航长度
      systemName: "", // 系统名称
      userName: sessionStorage.getItem("name"), // 登录用户
      isShowChangePsdwordDialog: false //修改密码diaLog
    };
  },

  computed: {
    projectName: {
      get() {
        return this.$store.state.projectName;
      },
      set(val) {}
    },
    projectCompay: {
      get() {
        return this.$store.state.projectCompay;
      },
      set(val) {}
    },
    widthScale() {
      return `width: ${this.mainNavWidth} + 'px'`;
    },
    titleLeftScale() {
      return `padding-left: ${this.mainNavWidth} + 'px'`;
    }
  },
  watch: {
    projectName(n, o) {
      console.log("no", n, o, sessionStorage.getItem("projectId"));
      this.projectName = n;
    },
    projectCompay(n, o) {
      //projectCompay
      console.log("no", n, o);
      this.projectCompay = n;
    },
    $route(routeValue) {
      if (routeValue) {
        if(routeValue.path == '/operationMaintenance' || routeValue.path == '/constructionOverview' || routeValue.path == '/home'){
          this.menuTwo = 0
        }
      }
    }
  },
  //创建
  created() {
    // console.log("皮肤换上", document.documentElement, document.documentElement.style, document.documentElement.style.setProperty("--mytest", "red"));

    this.projectName = this.$store.state.projectName; //项目名称
    this.projectCompay = this.$store.state.projectCompay; //项目公司

    this.isAdmin = sessionStorage.getItem("isAdmin");

    // if(!this.projectCompay){
    //   this.projectCompay = sessionStorage.getItem('projectCompay')
    // }

    // this.$bus.$on("all", this.all);
    this.user = sessionStorage.getItem("name"); //用户名
    // console.log('usr',sessionStorage.getItem('name'));

    console.log("newNavActive------", sessionStorage.getItem("newNavActive")); //保存的一级路由
    console.log("newPath-----", sessionStorage.getItem("newPath"));

    // 导航的选中样式
    var save = sessionStorage.getItem("newNavActive");
    if (save) {
      this.active = save;
    } else {
      setTimeout(() => {
        this.active = this.navList[0].id;
      }, 300);
    }

    var navTwoList = sessionStorage.getItem('navTwoList');
    console.log("navTwoList",navTwoList);
    var savePath = sessionStorage.getItem('newPath');
    var navTwoActive = sessionStorage.getItem('navTwoActive');
    if(savePath){
      this.$router.push(savePath)
    }
    if(navTwoList){
      this.list = JSON.parse(navTwoList)
    }
    if(navTwoActive){
      this.twoActive = navTwoActive
    }
    // 内存读取
    // var all = sessionStorage.getItem("all");
    // console.log("all", all);

    // if (all) {
    //   if (all == 0) {
    //     this.all(all);
    //   }
    // }
    this.$nextTick(() => {
      this.$bus.$on("handleClose", this.handleClose);
    });


  },

  //安装
  mounted() {
    this.customNav()
    this.getAppearanceData();
    this.navName(); //获取一级导航
    this.bridgeTreeData(); //桥梁树形信息
    this.getSbuNav(); //   获取副导航
    this.subNavObj = JSON.parse(sessionStorage.getItem("SUBNAVOBJ") || '{}');
    this.mouseName = this.subNavObj.name;
    console.log("mounted", this.subNavObj);

    // document.addEventListener('mouseup', function (e) {
    //   var modal = document.getElementById('qiaoliang-modal');
    //   console.log("dasda11",modal);
    //   console.log("dasda22",e.target);
    //   console.log(!modal.contains(e.target));
    //   if (!modal.contains(e.target)) {
    //       this.winBox = 0
    //   }
    // });
  },

  //方法
  methods: {
    customNav(){
      var routeValue = this.$route
      if(routeValue.path == '/operationMaintenance' || routeValue.path == '/constructionOverview' || routeValue.path == '/home'){
          this.menuTwo = 0
        }
    },
    addColor() {
      console.log("addcolor");
      addColor("blue");
    },
    subNavOver(item) {
      this.mouseName = item.name;
    },
    subNavOut() {
      // this.navSubVis = false;
    },
    handleNavSubItem(item, faitem) {
      console.log("item", item);
      this.subNavObj = {
        name: faitem.name,
        path: item.path
      };

      sessionStorage.setItem("SUBNAVOBJ", JSON.stringify(this.subNavObj));
      this.handleNavClick(item);
    },
    handleNavItem(e) {
      console.log("eee", e); //   e.target.dataset.name, e.target.dataset.subitem, e.target.dataset.subitem.path
      // let item = JSON.parse(e.target.dataset.subitem);
      if (!e.path) return;
      let item = e;
      this.subNavObj = {
        name: item.name,
        path: item.path
      };

      sessionStorage.setItem("SUBNAVOBJ", JSON.stringify(this.subNavObj));
      this.handleNavClick(item);
    },
    handleNavClick(item) {
      this.oldNavList = JSON.parse(JSON.stringify(this.navList));
      // this.navList = [];
      this.breadList = [];
      this.active = -999;

      this.go(item);
      this.navSubVis = false;
    },
    handleNavSub() {
      this.navSubVis = !this.navSubVis;
    },
    getSbuNav() {
      this.$axios.get(`${this.baseURL}navigation/leadings`).then((res) => {
        console.log("副导航", res);
        if (res.status == "200") {
          this.subnav = res.data.data;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // 获取外观配置数据
    getAppearanceData() {
      let name = this.userName;
      this.$axios.get(`${this.baseURL}base/aspect/get/config?name=${name}`).then((res) => {
        if (res.status == "200") {
          // document.title = res.data.data.systemName
          // document.getElementById('myIcon').href=`${this.baseURL}sys/sysImages/img/${res.data.data.firmLogoId}`
          this.mainNavWidth = res.data.data.mainLength;
          this.systemName = res.data.data.systemName;
          this.imgSrc = `${this.baseURL}sys/sysImages/img/${res.data.data.systemLogoId}`;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    handleClose() {
      console.log("ddddddddtttttttttttttttttttttttddddddd");
      this.winBox = 0;
    },
    searchShow() {
      console.log("视频");

      // if (this.win1 == 1) {
      //   this.tvShow();
      // }
      setTimeout(() => {
        if (this.winBox == 0) {
          this.winBox = 1;
          let projectId = sessionStorage.getItem("projectId");
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(projectId);
          });
        } else {
          this.winBox = 0;
        }
      }, 200);
    },
    back() {
      // this.userAdmin = false;
      // this.$router.push("/login");
      // sessionStorage.clear();
      this.userAdmin = false;
      if (this.srcPATH) {
        let OIframe = document.getElementById("ditu"); //监听gis
        console.log("OIframe", OIframe, this.srcPATH);
        let childDomain = this.srcPATH;
        OIframe.contentWindow.postMessage({ gis_address: "gis_address" }, childDomain);
      }
      setTimeout(() => {
        this.$router.push("/login");
        sessionStorage.clear();
      }, 50);
    },

    getSystem() {
      this.userAdmin = false;
      this.menuTwo = 1; //显示二级导航
      this.active = ""; // 一级导航为空
      this.twoActive = ""; // 二级导航为空
      this.list = [
        { id: "-1", name: "单位管理", path: "/company" },
        { id: "-2", name: "角色管理", path: "/roleList" },
        { id: "-3", name: "管理员管理", path: "/adminList" },
        { id: "-4", name: "项目管理", path: "/proList" }
      ];
      this.breadList = JSON.parse(JSON.stringify([{ name: "系统管理" }]));

      this.go(this.list[0]);
    },

    // 鼠标离开二级菜单
    mouseLeave() {
      console.log("mouseleave");
      this.userAdmin = false;
      this.timer = setTimeout(() => {
        this.menuTwo = 0;
        this.winBox = 0;
      }, 1000);
    },
    mouseEnter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    // 是否进入单桥
    all(e) {
      console.log("单桥是否显示", e);
      if (e == 0) {
        this.winBox = 1;
      } else {
        this.winBox = 0;
      }
    },

    // 显示隐藏菜单
    treeData() {
      if (this.win == 1) {
        this.win = 0;
      } else {
        this.win = 1;
      }
    },

    //桥梁树
    bridgeTreeData() {
      this.$axios
        .post(`${this.baseURL}pandect/select/likename?name=${this.name}`)
        .then((res) => {
          console.log("桥梁树形信息", res);
          var data = res.data.data;
          this.data = [];
          data.forEach((item, index) => {
            item.name = item.lineName;
            item.parentId = 0;
            item.id = index;
            item.list.forEach((item1, index1) => {
              item1.parentId = 1;
            });
          });

          this.data = data;
          console.log("data", this.data);

          var projectId = sessionStorage.getItem("projectId");
          this.currentNodeKey = projectId;
          console.log("projectId", projectId);
          if (this.$refs.tree) {
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(projectId);
            });
          }
        })
        .catch((err) => {});
    },
    handleNodeClick(data) {
      console.log("data点击桥梁列表", data);
      if (data.parentId != "0") {
        this.getData(data.id);
        // this.$refs.tree.setCurrentKey(data.id)
        this.$bus.$emit("handleNodeClick", data);
        console.log("点击桥梁列表emit后数据");
      }
    },

    // 获取一级导航
    navName() {
      // console.log('获取导航获取导航获取导航获取导航');
      // var path = `auth/authPermission/user/all/one`;
      var path = `navigation/all/one`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("获取导航获取导航获取导航获取导航", res.data.data);
          var nav = res.data.data;
          this.navList = nav;
          // 总览
          this.allView = nav[0];
          // this.active = nav[0].id

          // var newNav = nav.slice(1);
          // console.log(`newNav`, newNav);
          // this.navList = newNav;
          // if(newNav.length>6){
          //    // 一级导航
          //   this.navList = newNav;
          //   // 更多
          //   this.moreNav = newNav.slice(6);
          //   this.gengduoWin = 1;
          // }else{
          //   this.gengduoWin = 0;
          //    this.navList = newNav;
          // }
        })
        .catch((err) => {});
    },
    // 点击一级导航
    getNav(item) {
      let val = item.id;
      this.breadList = JSON.parse(JSON.stringify([item]));
      this.menuTwo = 1;
      console.log("点击一级导航val", val, "active", this.active, "item", item);

      if(item.path == '/operationMaintenance' || item.path == '/constructionOverview' ||  item.path == '/home'){
          this.menuTwo = 0
        }
      this.subNavObj = {
        name: "",
        path: ""
      };

      sessionStorage.setItem("SUBNAVOBJ", JSON.stringify(this.subNavObj));
      this.navSubVis = false;

      // if (this.active === val) {
      //   return;
      // }

      this.active = val;
      if (item.path) {
        this.list = [{
          name: item.name,
          id: item.id
        }];
        this.twoActive = item.id
        // this.breadList = [];
        sessionStorage.setItem("navTwoList",JSON.stringify(this.list))
        sessionStorage.setItem("navTwoActive",this.twoActive)
        this.go(item);
      } else {
        this.navTwoName(val); //获取二级导航
      }

      // 是否点过树，如果没有桥梁id，找到列表中第一个
      console.log("projectId", sessionStorage.getItem("projectId"));
      var projectId = sessionStorage.getItem("projectId");
      // this.currentNodeKey = projectId;
      if (this.$refs.tree) {
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(projectId);
        });
      }
      // 培森特大桥
      // if (projectId == 10004 || projectId == 10034) {
      //   this.bridgeTreeData1(); //获取桥梁树，并获取第一个树id
      //   // sessionStorage.setItem('projectId', this.projectId)
      // }
      if (projectId == null) {
        this.bridgeTreeData1(); //获取桥梁树，并获取第一个树id
        // sessionStorage.setItem('projectId', this.projectId)
      }
    },

    // 获取二级导航
    navTwoName(id) {
      // var path = `auth/authPermission/user/all/two/${id}`;
      var path = `navigation/all/two/${id}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("获取二级导航", res.data.data);
          this.list = res.data.data;
          sessionStorage.setItem("navTwoList",JSON.stringify(this.list))
          if (this.list.length) {
            this.go(this.list[0]);
          }
        })
        .catch((err) => {});
    },

    // 点击总览
    getAll() {
      // this.menuTwo = 0;
      console.log("allView", this.allView);
      // this.$router.push("/allView");
      this.$router.push("/home");
      this.active = "-8"; //点击总览清楚掉导航样式
      // sessionStorage.setItem("newPath", "/allView"); //保存点击后的路径名称
      sessionStorage.setItem("newPath", "/home"); //保存点击后的路径名称
      sessionStorage.setItem("newNavActive", ""); //保存点击后的一级导航
    },

    // 桥梁树形
    bridgeTreeData1() {
      this.$axios
        .post(`${this.baseURL}pandect/select/likename?name=`)
        .then((res) => {
          console.log("桥梁树形信息1111", res);
          var data = res.data.data;
          var id = data[0].list[0].id;
          this.projectId = id;
          // this.currentNodeKey = id;
          if (this.$refs.tree) {
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(projectId);
            });
          }
          console.log("22154500", data[0].list[0].id);
          this.getData(data[0].list[0].id);
        })
        .catch((err) => {});
    },

    getData(id) {
      console.log("获取信息");

      var data = {
        bid: id
      };
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data))
        .then((res) => {
          console.log("桥梁信息--------------------------------------------------", res.data.data);

          // this.projectName = res.data.data.bridge_name;
          // this.projectCompay = res.data.data.compayName;
          this.$store.commit("getProjectName", res.data.data.bridge_name);
          sessionStorage.setItem("projectName", res.data.data.bridge_name);

          this.$store.commit("getProjectCompay", res.data.data.compayName); //桥梁公司名称  getProjectCompay
          sessionStorage.setItem("projectCompay", res.data.data.compayName); //桥梁公司名称  compayName

          this.$store.commit("getProjectId", id); //项目id发送
          sessionStorage.setItem("projectId", id);

          this.projectId = id;
          // if (this.$refs.tree) {
           
          // }
          this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.projectId);
              // let path = sessionStorage.getItem('newPath')
              // console.log('path', path)
              // this.$router.push(path);
              // this.$router.go(0)
              // zhw
              this.reload();
            });
          this.currentNodeKey = id;
          this.$bus.$emit("getProjectId", id);
        })
        .catch((err) => {});
    },

    // 点击二级导航------跳转页面
    go(item) {
      // sessionStorage.setItem('projectId', '10008');
      // this.$store.commit('projectId','10008');

      this.twoActive = item.id;
      sessionStorage.setItem("navTwoActive",this.twoActive)
      console.log("点击二级导航", item);
      // this.menuTwo = 0;
      if (item.path == "/modalAnalysis") {
        //老的模态分析
        item.path = "/modelIndex"; //修改为新的模态分析路由
      }

      // this.$router.push(item.path);
      sessionStorage.setItem("newPath", item.path); //保存点击后的路径名称
      sessionStorage.setItem("newNavActive", this.active); //保存点击后的一级导航
      if (item.path == "/warning") {
        sessionStorage.setItem("WarningPath", JSON.stringify(item));
      }
      if (item.path == "/simulation") {
        sessionStorage.setItem("SimulationPath", JSON.stringify(item));
      }

      if (this.breadList.length > 1) {
        this.breadList.splice(1);
      }

      if (this.breadList[0] && this.breadList[0].name !== item.name) {
        this.breadList.push(item);
      } else {
        this.breadList.splice(0);
        this.breadList.push(item);
      }
      sessionStorage.setItem(BREADCRUMB, JSON.stringify(this.breadList));
      this.$store.commit("getBreadcrumb", this.breadList);

      this.isAuthority(item.path, item.id);

      //预警管理
      // if(item.name == '数据查看'){
      // this.data1 = []
      // this.authority1(37);
      // this.active =  this.data1.children[0].id;
      // var active = this.active;
      // this.loadSave(item.path, item.id)
      // this.$router.push(this.data1.children[0].path);
      // this.isAuthority(item.path, item.id);
      // }

      //数据查看
      // if(item.name == '数据查看'){
      // this.data1 = []
      // this.authority1(item.id);   //权限查询
      // this.active =  this.data1.children[0].id;
      //   var active = this.active;
      // this.$router.push(this.data1.children[0].path);
      // this.isAuthority(item.path, item.id);
      // this.loadSave(keyPath,active)
      // sessionStorage.setItem('dataAnalysisIndex','/timeDomainAnalysis')
      // }

      //基本信息
      // if(item.name == '基础信息'){
      // this.data1 = [];
      // this.authority1(8);
      // this.active = this.data1.children[0].id;
      // var active = this.active;
      // this.$router.push(this.data1.children[0].path);
      // this.loadSave(keyPath,active)
      // this.isAuthority(item.path, item.id);
      // }
    },
    isAuthority(path, id) {
      let arr = ["-1", "-2", "-3", "-4"]; //  判断系统管理进入
      if (arr.includes(id)) {
        let data = [
          { id: 100, pid: 27, name: "查看", level: 1, status: 1 },
          { id: 100, pid: 27, name: "查看", level: 2, status: 1 },
          { id: 100, pid: 27, name: "查看", level: 3, status: 1 },
          { id: 100, pid: 27, name: "查看", level: 4, status: 1 },
          { id: 100, pid: 27, name: "查看", level: 5, status: 1 },
          { id: 100, pid: 27, name: "查看", level: 6, status: 1 }
        ];
        sessionStorage.setItem(ISOPATIONS, JSON.stringify(data));
        this.$store.commit("getIsOptions", data);
        this.$router.push(path);

        return;
      }

      // this.$axios.get(`${this.baseURL}auth/authPermission/user/operation?id=${id}`).then((res) => {
      this.$axios.get(`${this.baseURL}navigation/operation?id=${id}`).then((res) => {
        console.log("是否可操作", res);
        // console.log('是否可操作', res.data.data);
        console.log("res.data", res.data.data);
        sessionStorage.setItem("isTrue", true); //权限
        // 权限更改中    12-1
        sessionStorage.setItem(ISOPATIONS, JSON.stringify(res.data.data));
        this.$store.commit("getIsOptions", res.data.data);

        if (this.active == "199") {
          sessionStorage.setItem("isTrue", true);
        }
        this.$router.push(path);
      });
    },

    isAuthorityqqqq(path, id) {
      // this.$axios.get(`${this.baseURL}auth/authPermission/user/operation?id=${id}`).then((res) => {
      this.$axios.get(`${this.baseURL}navigation/operation?id=${id}`).then((res) => {
        console.log("是否可操作", res);
        // console.log('是否可操作', res.data.data);
        console.log("res.data", res.data.data);
        sessionStorage.setItem("isTrue", res.data.data); //权限
        console.log("isTrue", sessionStorage.getItem("isTrue"));
        if (this.active == "199") {
          sessionStorage.setItem("isTrue", true);
        }
        console.log("路由跳转", path, this.$router);
        this.$router.push(path);
      });
    },

    // 隐藏
    hideNav() {
      this.menuTwo = 0;
    },

    gengduoShow() {
      if (this.gengduoWin == 1) {
        this.gengduoWin = 0;
      } else {
        this.gengduoWin = 1;
      }
    },
    changePwd() {
      this.isShowChangePsdwordDialog = true;
    }
  }
};
</script>
<style scoped lang="scss">
.show-select {
  height: 100%;
}

.user-admin {
  width: 169px;
  z-index: 999 !important;
  background: #102959;
  box-shadow: 0px 0px 4px 0px rgb(255 255 255 / 84%);
  border-radius: 4px;
  border: 1px solid rgba(28, 32, 34, 0.84);
  position: absolute;
  top: 60px;
  right: 9px;
  // padding: 10px 0;
  margin: 5px 0;
  transform-origin: center top;

  // display: none;
  li {
    list-style: none;
    line-height: 36px;
    padding: 10px 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: 0;
    color: #fff;

    &:hover {
      background: #192b5d;
    }

    .pointer {
      cursor: pointer;
    }
  }
}

// }
// .show-select:hover {
//   .user-admin {
//     display: block;
//   }
// }

.menuShow {
  width: 100%;
  height: 150px;
  z-index: 999;
  position: relative;
}

.menuShow1 {
  width: 100%;
  height: 83px;
  z-index: 999;
  position: relative;
}

.title {
  /* // padding-left: 486px; */
  /* padding-left: 550px; */
  padding-right: 30px;
  width: 100%;
  height: 83px;
  background-image: linear-gradient(180deg, #192b5d4d 0%, #00135A 86%);
  // background: url("../../assets/img/nav/nav-right.png");
  background-size: 100% 100%;
  position: absolute;
  // border-bottom: 2px solid #2e61e9;
  z-index: 99;
  border-bottom:2px solid #2E61E9 ;

}

.navImg {
  position: relative;

  .navTitleBox {
    position: relative;
    // top: -20px;
  }

  .navTitle {
    // position: absolute;
    // top: -40px;
    height: 18px;
    width: 272px;
    color: #fff;
    margin-left: 40px;
    text-align: left;
    letter-spacing: normal;
    font-size: 14px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
  }

  .navTitle1 {
    height: 80px;
    width: 200px;
    color: #fff;
    margin-left: 20px;
  }

  .navImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 134px;
    // height: 66px;
    height: 100%;
    // background: url("../../assets/images/bridge_shadow.png");
    background-size: 100% 100%;
  }
}

// .logo{
//   width: 40px;
//   height: 37px;
//   background: url('../assets/images/img/bridge_logo.png');
//   background-size: 100% 100%;
// }
// .logoTitle{
//   font-size: 30px;
//   font-weight: 600;
//   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
//   background: linear-gradient(180deg, #F4FBFF 0%, #BEEBFC 100%);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// }
.loginTile {
  position: absolute;
  /* width: 550px; */
  height: 83px;
  display: flex;
  align-items: center;
  // background: url("../../assets/img/nav/nav-left.png");
  background: url("../../assets/images/img/toptitle_bg.png");
  background-size: 100% 100%;
  z-index: 100;
  padding-left: 15px;
  img {
    height: 32px;
  }
  span {
    font-size: 21px;
    margin-left: 15px;
    font-family: SourceHanSansCN, SourceHanSansCN-Bold;
    font-weight: 700;
    color: #c8eefd;
    // font-family: SourceHanSansCN-Bold;
    // font-weight: 700;
    // font-size: 30px;
    // color: #ffffff;
    // letter-spacing: 0;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #ffffff, #c8eefd);
  }
}

.ul {
  height: 83px;

  li {
    /* // width: 125px; // */
    /* width: 115px; */
    /* // padding-top: 22px; */
    height: 83px;
    align-items: center;
    padding: 0 20px;
    .my-divider {
      position: absolute;
      right: 0;
      background: #3a4a7b;
      margin: 0;
    }
  }
}

.titleBox {
  .nav-r-icon {
    display: inline-block;
    width: 18px;
    height: 16px;
    background: url(../../assets/images/qianjin-2@2x.png) no-repeat;
    background-size: 100% auto;
    margin-left: 3px;
  }
  .border-div {
    position: absolute;
    width: 80%;
    height: 4px;
    background: linear-gradient(90deg, #00135a, #2b5fe4 51%, #00135b);
    // background-image: linear-gradient(90deg, rgba(255, 198, 0, 0) 0%, #f7db0f 49%, rgba(255, 188, 2, 0) 99%);
    left: 12.5px;
    bottom: 12px;
  }
}

.title1 {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  // text-wrap: nowrap;
}

.title2 {
  letter-spacing: 1px;
  background: linear-gradient(270deg, #5a72be 0%, #eeeeee 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0px;
}

.zonglan {
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #ccdaff;
  letter-spacing: 2px;
}

.activeColor {
  /* background: linear-gradient(90deg, #002fa7 0%, #101854 100%); */
  .title1 {
    color: #fff;
  }
}

.you {
  width: 18px;
  height: 16px;
  background: url("../../assets/images/img/qianjin-2 2.png");
  background-size: 100% 100%;
}

.shu {
  width: 2px;
  height: 33px;
  background: #3a4a7b;
  // background: #fff;
  // margin-left: 66px;
  // margin-right: 11px;
  position: relative;
  margin: 0 20px;
  // &::before {
  //   display: inline-block;
  //   height: 33px;
  //   width: 33px;
  //   content: '';
  //   position: absolute;
  //   left: -45px;
  //   background: url(../../assets/images/gengduo_icon@2x.png) no-repeat;
  //   background-size: cover;
  // }
}

.xiala {
  margin-left: 115px;
  position: absolute;
  z-index: 100;
  top: 90px;

  .win {
    position: absolute;
    right: 60px;
    top: 0;
  }

  .padd0 {
    padding-left: 0;
    padding-right: 0;
  }
}

.menuTwo {
  position: absolute;
  top: 102px; //92
  width: calc(100% - 40px);
  height: 52px;
  // background: rgba(0, 15, 44, 0.7);
  // background: #000f2f;
  z-index: 99 !important;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid #0C3380;
  border-bottom: 0;
  .img_right{
    width: 114px;
    height: 50px;
  }
}
.my-bg {
  // background-image: linear-gradient(180deg, #02133a00 0%, #062160d4 86%);
  background-image: linear-gradient(180deg, #0c2157 0%, #0d215b 100%);
  background-size: cover;
}
.topBr {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #112881 0%, #7689c7 26%, #102779 100%);
}

.menuTwoBox {
  // line-height: 64px;
}

.shu1 {
  width: 2px;
  height: 33px;
  background: #1d2e5e;
  margin-left: 25px;
  margin-right: 25px;
}

.ul1 {
  li {
    color: #7387c6;
    margin-right: 50px;
  }
}

::v-deep .newUi {
  .el-menu {
    background-color: transparent !important;
  }

  ul.el-menu .el-menu--horizontal {
    background: transparent !important;
  }

  ul.el-menu.el-menu--horizontal > .el-menu-item {
    height: 64px !important;
    line-height: 64px !important;
  }

  .el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 64px !important;
    line-height: 64px !important;
  }

  .el-menu--horizontal .el-menu .el-menu-item,
  .el-menu--horizontal .el-menu .el-submenu__title {
    background: transparent !important;
  }

  // 触摸二级导航颜色
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background: transparent !important;
  }

  // 三级导航背景色
  .el-menu--horizontal .el-menu .el-menu-item,
  .el-menu--horizontal .el-menu .el-submenu__title {
    background-color: transparent !important;
  }
}

.gengduo {
  position: absolute;
  width: 100px;
  right: 160px;
  top: 74px;
  z-index: 9;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 8px;
  border: 1px solid;
  border-image: linear-gradient(300deg, rgba(155, 169, 203, 1), rgba(61, 107, 208, 1)) 1 1;
}

.twoUl {
  li {
    // margin-right: 20px;
  }

  .ulShu {
    // margin-left: 20px;
    // margin-right: 20px;
    width: 1px;
    height: 20px;
    background: #3a4a7b;
  }

  .lifu {
    position: relative;

    // padding-right: 20px;
    // padding-left: 20px;
    // border-right: 1px solid #3A4A7B;
    .imgbox {
      position: absolute;
      top: 30px;
      height: 5px;
      width: 100%;
      background-image: linear-gradient(90deg, #00135a00 0%, #1E87FF 54%, #00135b00 100%);
    }
  }

  .twoActive1 {
    color: #ccdaff;
  }

  .twoActive2 {
    color: #ffffff;
  }
}

// 切换箭头方向
.tab1 {
  width: 28px;
  height: 22px;
  background: url("../../assets/images/all/xiala_icon.png");
  background-size: 100% 100%;
}

.tab2 {
  width: 28px;
  height: 22px;
  background: url("../../assets/images/all/xiala_icon.png");
  background-size: 100% 100%;
  transform: rotate(180deg);
}

::v-deep .el-tree-node__children .is-current {
  background-color: #002fa7;
}

// 桥梁查询
::v-deep .win {
  position: absolute;
  right: 89px;
  top: 113px;
  z-index: 1;
  width: 318px;
  border-radius: 8px;
  border: 1px solid #0c49af;
  background: rgba($color: #000000, $alpha: 0.4);

  .winTitle {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .winTitle1 {
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px 8px 8px 8px;
    background: linear-gradient(128deg, #03195b 0%, #0c49af 100%);
  }

  .fu {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;

    .zi {
      position: absolute;
      right: 28px;
      top: 24px;
      padding-left: 12px;
      border-left: 1px solid #3a4a7b;
    }

    .el-input {
      width: 100%;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #2667db;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    div.el-input .el-input__inner:hover,
    div.el-input .el-input__inner:focus {
      border-radius: 20px !important;
      border: 1px solid #2667db;
    }

    div.el-input .el-input__inner {
      border-radius: 20px !important;
    }
  }

  .winBottom {
    // padding-left: 15px;
    // padding-right: 15px;
  }
}

.allTitle {
  font-weight: 600 !important;
}

.line1 {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #112881 0%, #7689c7 26%, #102779 100%);
  position: absolute;
  top: 82px; //92
  z-index: 4;
}

.line2 {
  width: 100%;
  height: 2px;
  position: absolute;
  top: 134px; //92
  background: linear-gradient(90deg, #112881 0%, #7689c7 26%, #102779 100%);
  z-index: 4;
}

::v-deep .el-dropdown-link {
  z-index: 999;
}

.nav-bg {
  width: 100%;
  z-index: 100;
  position: relative;
  // height: 83px;
  // background-image: url(../../assets/images/img/nav-bg.png);
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}

::v-deep .el-menu {
  background-color: transparent !important;
}

::v-deep .el-submenu .el-submenu__title {
  background-color: transparent !important;
  border-bottom: none !important;
  color: #ffffff !important;
}

::v-deep .el-menu--horizontal .el-menu--popup.el-menu.el-menu--popup-bottom-start,
::v-deep > .el-menu--horizontal > .el-menu--popup {
  background-color: transparent !important;
  background: url(../../assets/images/img/juxing.png) no-repeat !important;
  background-size: 100% 100%;
  border: 1px solid red;
}
::v-deep .el-menu--horizontal .el-menu--popup .el-menu-item {
  background-color: transparent !important;
}

.nav-icon {
  position: relative;
  // width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  // line-height: 83px;
}

.nav-icon .nav-icon-ul {
  position: absolute;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 80px;
  width: 137px;
  background: url(../../assets/images/img/juxing.png) no-repeat;
  background-size: 100% 100%;
  text-align: left;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #82abff;
  left: -100px;
  .nav-icon-item {
    height: 32px;
    line-height: 32px;
    padding-left: 12px;
    // margin-top: 10px;
    // margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
    .nav-icon-sub-ul {
      // padding: 10px 0;
      margin-top: 10px;
      margin-bottom: 10px;
      display: none;
      top: -10px;
      right: -137px;
      position: absolute;
      width: 137px;
      background: url(../../assets/images/img/jiuxing1.png) no-repeat;
      background-size: 100% 100%;
      text-align: left;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #82abff;
      .nav-icon-sub-item {
        height: 24px;
        line-height: 24px;
        padding-left: 12px;
      }
      .nav-icon-sub-text {
        width: 100%;
        height: 100%;
        padding-left: 10px;
        box-sizing: border-box;
      }
      .nav-icon-sub-text:hover,
      .nav-icon-sub-text.active {
        background: #1768e8;
        color: #ffffff;
      }
    }
  }
  .nav-icon-item:hover,
  .nav-icon-item.active {
    background: #1768e8;
    color: #ffffff;
    .nav-icon-sub-ul {
      display: block;
    }
  }
}
</style>
