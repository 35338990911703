<template>
  <!-- 导航操作 点击切换 -->
  <div class="nav">
    <div class="title textC box around" v-if="nav == 1">
      <div class="box center" @click="go">
        <div class="iconImg mR5">
          <img :src="imgSrc" alt="" />
        </div>
        <!-- <div class="text">云奚数字孪生平台</div> -->
        <!-- <div class="text">桥梁群健康监测与评估云平台</div> -->
        <div class="text">{{ sysName }}</div>
      </div>
    </div>
    <menuShow v-if="nav == 2"></menuShow>
    <div class="date box end center" v-if="nav == 1">
      <img :src="img" alt="" style="width: 28px" />
      <div class="mR10">{{ text }}</div>
      <div class="">{{ time }}</div>
    </div>
    <!-- 切换导航的箭头 :class="nav==2?'xuanzhuan90':''"-->
    <div class="box around pointer iconBox" style="width: 100%" v-if="nav == 1">
      <img src="../../assets/images/all/xiala_icon.png" @click="tabNav" alt="" />
    </div>
  </div>
</template>

<script>
import menuShow from "./menuShow";
import moment from "moment";

import myBMap from "../../utils/bmap.js";

let timer;

export default {
  name: "mynav",
  components: {
    menuShow
  },
  data() {
    return {
      nav: "2",
      time: "",
      text: "",
      img: "",
      imgSrc: "", // 系统logo src
      sysName: "",
      userName: sessionStorage.getItem("name") // 登录用户
      // sendData: {} // 传送数据
    };
  },
  //创建
  created() {},

  beforeDestroy() {
    clearInterval(timer);
  },

  //安装
  mounted() {
    var _this = this; //声明一个变量指向vue实例this,保证作用域一致
    timer = setInterval(function () {
      _this.time = _this.getCurrentDateTime(); //修改数据date
    }, 200);
    this.dayData(); //天气预报
    // this.getLocalIp();
  },
  created() {
    this.getAppearanceData();
  },

  //方法
  methods: {
    // 获取外观配置数据
    getAppearanceData() {
      let name = this.userName;
      this.$axios.get(`${this.baseURL}base/aspect/get/config?name=${name}`).then((res) => {
        if (res.status == "200") {
          console.log("外观配置----------------", res);
          document.title = res.data.data.systemName;
          document.getElementById("myIcon").href = `${this.baseURL}sys/sysImages/img/${res.data.data.webId}`;
          this.sysName = res.data.data.bigTitle;
          this.imgSrc = `${this.baseURL}sys/sysImages/img/${res.data.data.systemLogoId}`;
          // this.sendData = res.data.data
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    //  日期
    getCurrentDateTime() {
      return moment(new Date()).format("YYYY" + "年" + "MM" + "月" + "DD" + "日" + "" + "HH:mm:ss"); //12:20:12  2020年7月24日
    },

    // 天气预报
    dayData(ip) {
      myBMap.init().then((BMap) => {
        const geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(
          (position) => {
            console.log("position", position);
            var temp = position.point.lat + "," + position.point.lng;
            // this.city = position.address.city; //获取城市信息

            // liangling 2022.7.7
            this.$axios.get("./weather.json").then((res) => {
              console.log("存储的城市代码-----------------------", res);
              res.data.forEach((item) => {
                if (position.address.city.indexOf(item.name) != -1) {
                  this.$axios.get(`https://devapi.qweather.com/v7/weather/now?location=${item.id}&key=48ab12dad83e4f3fa0a5617907c261f7`).then((res) => {
                    console.log("tianqi", res);
                    if (res.data && res.data.now) {
                      this.text = res.data.now.text;
                      this.temp = res.data.now.temp; //温度
                      this.humidity = res.data.now.humidity; //湿度

                      var img = res.data.now.icon;
                      this.img = require(`../../assets/images/weather/${img}.png`);
                    }
                  });
                }
              });
            });

            // console.log('99999999999999--------------', position.address.city)

            // this.$axios.get("/dmap/reverse_geocoding/v3/?ak=GIIl1R2r41ViPekccN93K63u6tolraXb&location&output=json&coordtype=wgs84ll&location=" + temp).then((res) => {
            //   if (res.status == 0) {
            //   }
            // });

            // this.$axios
            //   .get(`http://wthrcdn.etouch.cn/weather_mini?city=${this.city}`)
            //   .then((response) => {
            //     console.log("城市信息", response.data.data);
            //   })
            //   .catch(function (error) {
            //     console.log(error);
            //   });
            //    此方法可用，备用 zhw

            // this.$axios.get(`/api/local/v2/city/lookup?location=${this.city}&key=48ab12dad83e4f3fa0a5617907c261f7`).then((res) => {
            //   console.log("城市", res);
            //   this.$axios.get(`https://devapi.qweather.com/v7/weather/now?location=${res.data.location[0].id}&key=48ab12dad83e4f3fa0a5617907c261f7`).then((res) => {
            //     console.log("tianqi", res);
            //     this.text = res.data.now.text;
            //     this.temp = res.data.now.temp; //温度
            //     this.humidity = res.data.now.humidity; //湿度

            //     var img = res.data.now.icon;
            //     this.img = require(`../../assets/images/weather/${img}.png`);
            //   });
            // });
          },
          (e) => {
            console.log(e);
            console.log("定位失败");
          },
          { provider: "baidu" }
        );
      });

      return;

      // console.log("ip", ip);
      // // https://blog.csdn.net/qq_40657585/article/details/102475218
      // this.$axios
      //   .get(`https://devapi.heweather.net/v7/weather/now?location=101100501&key=48ab12dad83e4f3fa0a5617907c261f7&gzip=n`)
      //   // .get(`https://devapi.heweather.net/v7/weather/now?location=${ip}&key=48ab12dad83e4f3fa0a5617907c261f7&gzip=n`)
      //   // .get(`https://www.toutiao.com/stream/widget/local_weather/data/?city=${ip}`)
      //   // .get(`/api/local/v2/city/lookup?location=beij&key=48ab12dad83e4f3fa0a5617907c261f7`)
      //   .then((res) => {
      //     console.log("天气预报返回", res);

      //     this.text = res.data.now.text;
      //     this.temp = res.data.now.temp; //温度
      //     this.humidity = res.data.now.humidity; //湿度

      //     var img = res.data.now.icon;
      //     this.img = require(`../../assets/images/weather/${img}.png`);
      //   })
      //   .catch((err) => {});
    },
    // 获取登录地ip
    getLocalIp() {
      this.$axios
        .get(`/api/cityjson?ie=utf-8`)
        .then((res) => {
          console.log("地址返回", res);
          if (res.status == 200) {
            console.log("dizhifanhui", res.data);
            window.eval(res.data);
            console.log("ssssssssssssss", returnCitySN);
            this.dayData(returnCitySN.cname);
          }
        })
        .catch((err) => {});
    },
    go() {
      this.$bus.$emit("changeModel", "1");
      //   this.$router.push('/main');
    },
    //  切换导航
    tabNav() {
      if (this.nav == 1) {
        this.nav = 2;
      }
      // else{
      //     this.nav = 1;
      // }
    }
  }
};
</script>
<style scoped lang="scss">
.nav {
  width: 100%;
  //  height: 100%;
  text-align: center;
  margin: 0 auto;
}

.title {
  // background: url("../../assets/images/all/top.png");
  background: url("../../assets/images/sansheng/map_title@2x.png");
  background-size: 100% 100%;
  height: 103px;
  line-height: 103px;

  .text {
    font-weight: bold;
    letter-spacing: 4px;
    font-size: 22px;
  }
}

.iconImg {
  /* width: 41px; */
  /* height: 39px; */
  /* background: url("../../assets/images/img/bridge_logo.png"); */
  /* background-size: 100% 100%; */
  img {
    height: 32px;
  }
}

.aa {
  img {
  }
}

.iconBox {
  width: 26px;
  height: 21px;
}

.date {
  position: absolute;
  right: 0;
  top: 0;
  width: 449px;
  height: 42px;
  font-size: 14px;
  color: #fff;
  line-height: 42px;
  background: url("../../assets/images/all/tianqi_bg.png");
  background-size: 100% 100%;
  text-align: right;
  padding-right: 20px;
}

.xuanzhuan90 {
  transform: rotate(180deg);
}
</style>
