<template>
  <!-- 单桥的信息框 -->
  <div class="aloneWin">
    <div class="alontMain box box_column">
      <div class="aloneBox">
        <div style="margin-left: 40px; margin-bottom: 10px; margin-right: 30px; margin-top: 20px">
          <div class="box justify">
            <div class="title">{{ list.bridge_name }}</div>
            <div class="iconfont icon-fangda_icon pointer" style="color: #082369" @click="getU3d"></div>
            <!-- <div>
                  <img src="../../assets/images/alone/fangdashijiao_icon.png" alt="">
                </div> -->
          </div>
          <div class="box center">
            <div>
              <div class="div1">桥型</div>
              <div class="div2">{{ list.bridge_type }}</div>
            </div>
            <div class="shu"></div>
            <div>
              <div class="div1">线路</div>
              <div class="div2">{{ list.line_name }}</div>
            </div>
            <div class="shu"></div>
            <div>
              <div class="div1">状态</div>
              <div class="div2">{{ list.status }}</div>
            </div>
            <div class="shu"></div>
            <div>
              <div class="div1" v-if="showNo == 1">健康评估</div>
              <div class="div2">{{ num }}</div>
            </div>
          </div>
        </div>

        <div class="imgDiv">
          <img :src="src" alt="" @click="getU3d" />
        </div>
        <div class="box center" style="padding-left: 20px; padding-top: 20px">
          <div class="box center">
            <div style="margin-right: 32px">
              <div class="div3">{{ list.targetCount }}个</div>
              <div class="div4">测点数量</div>
            </div>
            <div>
              <div class="div3">{{ list.onlineRate }}</div>
              <div class="div4">在线率</div>
            </div>
          </div>
          <div class="shu"></div>
          <div class="box center">
            <div style="margin-right: 32px">
              <!-- <div class="color1 fs18">{{list.onlineRateList.normal.count}}</div> -->
              <div class="color1 fs18">{{ number1 }}</div>
              <div class="box center">
                <div class="iconfont icon-zaixian_icon color1"></div>
                <div class="color1 fs12">在线</div>
              </div>
            </div>
            <div style="margin-right: 32px">
              <!-- <div class="color2 fs18">{{list.onlineRateList.offline.count}}</div> -->
              <div class="color2 fs18">{{ number2 }}</div>
              <div class="box center">
                <div class="iconfont icon-lixian_icon color2"></div>
                <div class="color2 fs12">离线</div>
              </div>
            </div>
            <div style="margin-right: 32px">
              <!-- <div class="color3 fs18">{{list.onlineRateList.fault.count}}</div> -->
              <div class="color3 fs18">{{ number3 }}</div>
              <div class="box center">
                <div class="iconfont icon-guzhangzhuangtai-2 color3"></div>
                <div class="color3 fs12">故障</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="box around">
            <span class='triangleDiv'>▼</span>
          </div> -->
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "aloneWin",
  data() {
    return {

      projectId: "",
      projectBox: "",
      list: {
        onlineRateList: { normal: { count: "" } },
        onlineRateList: { offline: { count: "" } },
        onlineRateList: { fault: { count: "" } }
      },
      number1: "",
      number2: "",
      number3: "",

      src: "",
      num: "",
      timer: null,
      showNo: "1"
    };
  },
  //创建
  created() {

    this.projectId = sessionStorage.getItem("projectId");

  },

  //安装
  mounted() {
    this.$bus.$on("getProjectId", this.getProjectId);

    if (this.projectId == "10009") {
      this.showNo = 0;
    }
  },

  //方法
  methods: {
    getProjectId(e) {
      console.log("单桥弹框", e);
      this.src = ""
      this.projectBox = e;
      // if (this.timer) {
      //   clearInterval(this.timer)
      //   this.timer = null
      // }
      this.getData(e); //桥梁信息
      this.getImg(e); //桥梁图片
      // this.timer = setInterval(() => {
      //   this.getData(this.projectBox); //桥梁信息
      //   // this.getImg(e); //桥梁图片
      // }, 10000);

    },
    clearInter() {
      console.log("清除定时器", this.timer)
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    // 获取信息
    getData(id) {

      var data = {
        bid: id
      };
      // 获取桥梁健康值
      this.aloneNum(id);
      this.$axios
        .post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data))
        .then((res) => {
          console.log("桥梁信息", res.data.data);
          var data = res.data.data;
          this.list = data;
          this.number1 = this.list.onlineRateList.normal.count;
          this.number2 = this.list.onlineRateList.offline.count;
          this.number3 = this.list.onlineRateList.fault.count;

          // this.$store.commit("getProjectName", bridge_name);
        })
        .catch((err) => { });
    },

    // 单桥健康值
    aloneNum(id) {
      this.$axios
        .post(`${this.baseURL}pandect/project/grade/${id}`)
        .then((res) => {
          console.log("单桥健康", res.data.data);
          this.num = res.data.data.healthGrade;
        })
        .catch((err) => { });
    },

    // 封面图片
    getImg(id) {
      var data = {
        bid: id
      };
      this.$axios
        .get(`${this.baseURL}sys/sysImages/cover/${id}`)
        .then((res) => {
          // console.log('桥梁图片', res.data.data);
          var id = res.data.data[0].id;
          console.log("id", id);
          this.imgSrc1(id);
        })
        .catch((err) => { });
    },

    imgSrc1(id) {
      console.log('imgsrc1', id)
      this.src = `${this.baseURL}sys/sysImages/img/${id}`;
    },

    getU3d() {
      console.log("projectBox-------------------", this.projectBox);
      if (this.projectBox == "10008") {
        //10008  东和槽
        console.log("projectId", this.projectId);
        this.$store.commit("getProjectName", this.list.bridge_name);
        sessionStorage.setItem("projectName", this.list.bridge_name);

        this.$store.commit("getProjectCompay", this.list.compayName); //桥梁公司名称  getProjectCompay
        sessionStorage.setItem("projectCompay", this.list.compayName); //桥梁公司名称  compayName

        this.$store.commit("getProjectId", this.projectBox); //项目id发送
        sessionStorage.setItem("projectId", this.projectBox);
        this.projectId = this.projectBox;

        this.$router.push("/model3D");
      }
    }
  },
  beforeDestroy() {
    console.log('alonewin-beforeDestroy')

  }
};
</script>
<style scoped lang="scss">
.aloneWin {
  width: 100%;
  height: 400px;
}

.alontMain {
  width: 100%;
  height: 390px;
  overflow: hidden;

  .title {
    font-size: 24px;
    color: #282d32;
  }

  .shu {
    width: 1px;
    height: 28px;
    background: #979797;
    margin-left: 16px;
    margin-right: 16px;
  }

  .div1 {
    font-size: 12px;
    color: rgba($color: #282d32, $alpha: 0.5);
  }

  .div2 {
    font-size: 14px;
    color: #282d32;
  }

  .div3 {
    font-size: 18px;
    color: #282d32;
  }

  .div4 {
    font-size: 12px;
    color: rgba($color: #282d32, $alpha: 0.5);
  }

  .imgDiv {
    width: 100%;
    height: 198px;

    img {
      width: 100%;
      height: 100%;
      background: #fff;
    }
  }

  .color1 {
    color: #3fc677;
  }

  .color2 {
    color: #8a94a6;
  }

  .color3 {
    color: #ff8a4b;
  }
}

.aloneBox {
  width: 456px;
  height: 390px;
  background: url("../../assets/images/alone/active-users_bg.png");
  background-size: 100% 100%;
  // background: #fff;
  // background: #fff;
}

.triangleDiv {
  position: relative;
  top: -8px;
  font-size: 18px;
  color: #fff;
  text-shadow: 0 1px 1px rgb(255, 255, 255);
}
</style>
