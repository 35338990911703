<template>
<!-- 单桥 -->
  <div class="alone">

  </div>
</template>

<script>

export default {
  name: 'alone',
  data(){
      return{

      }
  },
  //创建
  created(){

  },

  //安装
  mounted(){

  },
  
  //方法
  methods:{

  }


}
</script>
<style  scoped lang="scss">
    .alone{
        width: 100%;
        height: 100%;
        // background: url('../assets/images/img/alone/daohang_bg.png');
        background-size: 100% 100%;
    }




</style>